import { UserDetails } from "@/graphql/schema";
import { getDaysUntil, isFuture, isToday } from "./utils";
import { progressingEvent, StreakStatus } from "../progressing";

export const checkStreakLongPauseStatus = async (user: UserDetails) => {
    const tz = user.timeZone ?? undefined;

    if (isToday(user.longPauseEnd, tz)) {
        // long pause ended today
        progressingEvent.notify({ [StreakStatus.LONG_PAUSE_ENDED]: {} });
        return;
    }

    if (isFuture(user.longPauseEnd, tz)) {
        const numOfDaysRemaining = getDaysUntil(user.longPauseEnd, tz);
        progressingEvent.notify({ [StreakStatus.RESUME_LONG_PAUSE]: { daysRemaining: numOfDaysRemaining } });
    }

    // no active long pause, we're not interested in this case.
};

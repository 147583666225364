import { client } from "@/utils/client/graphql";
import { viewItems } from "@/graphql/mutations";
import { TemporaryViewInput } from "@/graphql/schema";

export const callViewItems = async (input: TemporaryViewInput) => {
    const { data } = await client.mutate({
        mutation: viewItems,
        variables: { input },
    });

    return data.viewItems;
};

"use client";

import { AuthPopupContextProvider } from "@/features/Auth/AuthPopup/hook/useAuthPopupContext";
import { AuthSlideContextProvider } from "@/features/Auth/AuthSlide/hook/useAuthSlideContext";
import React from "react";

/**
 * For now, we don't dynamically import, since it will cause a screen flash as the components load in
 */
const AuthDialogProviders = ({ children }: { children: React.ReactNode }) => {
    return (
        <AuthSlideContextProvider>
            <AuthPopupContextProvider>{children}</AuthPopupContextProvider>
        </AuthSlideContextProvider>
    );
};

export default AuthDialogProviders;

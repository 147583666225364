"use client";
import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";
import { useUser } from "pointer-sdk";
import { useEffect } from "react";

const SyncPointerData = () => {
    const { userId, isLoading } = useCurrentUser({ waitForFallbackData: true, from: "SyncPointerData" });
    const { setUserData } = useUser();

    useEffect(() => {
        if (!userId || isLoading) return;

        setUserData({
            userId,
        });
    }, [userId, isLoading]);

    return null;
};

export default SyncPointerData;

import { iconSizes } from "@/utils/iconProps";

const StreakPauseIcon = ({
    showPauseLine,
    showResumeIcon,
    size = iconSizes.LG,
    ...props
}: { size?: number; showPauseLine?: boolean; showResumeIcon?: boolean } & React.SVGProps<SVGSVGElement>) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 44 45"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
            style={{
                position: "relative",
                ...props.style,
            }}>
            <path
                d="M40.3307 22.1644C40.3307 32.2895 32.1225 40.4977 21.9974 40.4977C11.8722 40.4977 3.66406 32.2895 3.66406 22.1644C3.66406 12.0392 11.8722 3.83105 21.9974 3.83105C32.1225 3.83105 40.3307 12.0392 40.3307 22.1644Z"
                fill="#B3B6B5"
            />
            {showPauseLine && (
                <>
                    <path
                        d="M26.5217 17.5388C26.5217 17.0277 26.1763 16.6133 25.7504 16.6133H24.2088C23.7829 16.6133 23.4375 17.0277 23.4375 17.5388V26.7906C23.4375 27.3018 23.7829 27.7161 24.2088 27.7161H25.7504C26.1763 27.7161 26.5217 27.3018 26.5217 26.7906V17.5388Z"
                        fill="white"
                    />
                    <path
                        d="M20.5606 17.5388C20.5606 17.0277 20.2154 16.6133 19.7893 16.6133H18.2478C17.8219 16.6133 17.4766 17.0277 17.4766 17.5388V26.7906C17.4766 27.3018 17.8219 27.7161 18.2478 27.7161H19.7893C20.2154 27.7161 20.5606 27.3018 20.5606 26.7906V17.5388Z"
                        fill="white"
                    />
                </>
            )}
            {showResumeIcon && (
                <path
                    d="M18.607 13.2931C19.1515 13.6074 19.1515 14.3932 18.607 14.7076L12.307 18.3449C11.7626 18.6592 11.082 18.2663 11.082 17.6376L11.082 10.363C11.082 9.73435 11.7626 9.34143 12.307 9.65576L18.607 13.2931Z"
                    fill="white"
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "scale(1.8) translate(-5%,-3%)",
                    }}
                />
            )}
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M21.9974 37.7477C30.6038 37.7477 37.5807 30.7708 37.5807 22.1644C37.5807 13.558 30.6038 6.58105 21.9974 6.58105C13.391 6.58105 6.41406 13.558 6.41406 22.1644C6.41406 30.7708 13.391 37.7477 21.9974 37.7477ZM21.9974 40.4977C32.1225 40.4977 40.3307 32.2895 40.3307 22.1644C40.3307 12.0392 32.1225 3.83105 21.9974 3.83105C11.8722 3.83105 3.66406 12.0392 3.66406 22.1644C3.66406 32.2895 11.8722 40.4977 21.9974 40.4977Z"
                fill="#4A4A4A"
            />
        </svg>
    );
};

export default StreakPauseIcon;

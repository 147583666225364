"use client";

import { useCallback, useMemo, useState } from "react";
import { createContext, useContextSelector } from "@knowt/syncing/utils/use-context-selector";
import { themeColors } from "@/utils/themeColors";
import noop from "@knowt/syncing/utils/noop";

const initialConfirmState = {
    open: false,
    title: "",
    subtitle: "",
    onConfirm: () => null,
    onClose: () => null,
    buttonText: "Confirm",
    cancelButtonText: "Cancel",
    buttonColor: themeColors.primary,
    buttonTextColor: themeColors.primaryDark,
    style: {},
    rounded: true,
    cardSize: "md" as const,
};

type DialogContextValue = {
    openConfirmDialog: (values: {
        open?: boolean;
        title: string;
        subtitle: string;
        onConfirm: () => void;
        onClose?: () => void;
        buttonText?: string;
        cancelButtonText?: string;
        rounded?: boolean;
        buttonColor?: string;
        buttonTextColor?: string;
        style?: React.CSSProperties;
        cardSize?: "sm" | "md" | string;
        spacing?: number;
        subtitleFontSize?: string;
        subtitleFontWeight?: string;
        simpleCancelBtn?: boolean;
    }) => void;
    closeConfirmDialog: () => void;
    confirmDialog: {
        open: boolean;
        title: string;
        subtitle: string;
        onConfirm: () => void;
        onClose: () => void;
        buttonText: string;
        cancelButtonText: string;
        rounded: boolean;
        buttonColor: string;
        buttonTextColor: string;
        style: React.CSSProperties;
        cardSize: "sm" | "md";
    };
};

const DialogContext = createContext<DialogContextValue>({
    openConfirmDialog: noop,
    closeConfirmDialog: noop,
    confirmDialog: initialConfirmState,
});

export const DialogContextProvider = ({ children }: { children: React.ReactNode }) => {
    const [confirmDialog, setConfirmDialog] = useState(initialConfirmState);

    const updateConfirmDialog = useCallback(values => {
        setConfirmDialog({ ...initialConfirmState, ...values });
    }, []);

    const openConfirmDialog = useCallback(
        values => {
            updateConfirmDialog({ ...values, open: true });
        },
        [updateConfirmDialog]
    );

    const closeConfirmDialog = useCallback(() => {
        updateConfirmDialog({ ...initialConfirmState });
    }, [updateConfirmDialog]);

    const value = useMemo(
        () => ({
            confirmDialog,
            openConfirmDialog,
            closeConfirmDialog,
        }),
        [closeConfirmDialog, confirmDialog, openConfirmDialog]
    );

    return <DialogContext.Provider value={value}>{children}</DialogContext.Provider>;
};

export const useDialogContextSelector = <T,>(selector: (state: DialogContextValue) => T) =>
    useContextSelector(DialogContext, selector);

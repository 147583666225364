import CircularRectTextButton from "@/components/CircularButton/styled/CircularRectTextButton";
import { FlexColumn, FlexColumnAlignJustifyCenter, FlexColumnJustifyCenter } from "@/components/Flex";
import { STORAGE_KEYS } from "@/config/constants";
import ResponsiveDialog from "@/features/ResponsiveDialog/ResponsiveDialog";
import DialogCloseBtn from "@/features/ResponsiveDialog/styled/DialogCloseBtn";
import Mixpanel from "@/utils/analytics/Mixpanel";
import { dynamicKeyValueStore } from "@/utils/dynamicKeyValueStore";
import { themeColors } from "@/utils/themeColors";
import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";
import Image from "next/image";
import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { Confetti } from "../Confetti";

export const showValentinesDayPopup = () =>
    !dynamicKeyValueStore.get(STORAGE_KEYS.VALENTINES_DAY_POPUP_DONE) &&
    new Date().getMonth() === 1 &&
    new Date().getDate() === 14;

const ValentinesDayPopup = () => {
    const { userId, user } = useCurrentUser();

    const [isOpen, setIsOpen] = useState(showValentinesDayPopup() && !!userId);
    const alreadyReported = useRef(false);

    const onClose = async () => {
        await Mixpanel.track("Valentines Day Popup Closed Without Sharing");
        dynamicKeyValueStore.set(STORAGE_KEYS.VALENTINES_DAY_POPUP_DONE, "true");
        setIsOpen(false);
    };

    const imageNumber = Math.round(Math.random() * 10);

    useEffect(() => {
        if (isOpen && !alreadyReported.current) {
            Mixpanel.track("Valentines Day Popup Shown");
            alreadyReported.current = true;
        }
    }, [isOpen]);

    const renderContent = () => (
        <FlexColumn style={{ position: "relative", zIndex: 1 }}>
            <DialogCloseBtn onClick={onClose} style={{ marginLeft: "auto", marginBottom: "2rem" }} />

            <FlexColumnJustifyCenter
                style={{
                    padding: "0 3.2rem",
                    marginBottom: "3.2rem",
                    width: "100%",
                }}>
                <Image
                    alt="valentines day love ❤️❤️❤️❤️❤️"
                    src={`/images/2025-Valentines-2/${imageNumber}.png`}
                    height={450}
                    width={450}
                    style={{
                        objectFit: "contain",
                        borderRadius: "3rem",
                        overflow: "hidden",
                        width: "100%",
                        height: "auto",
                    }}
                />
            </FlexColumnJustifyCenter>

            <FlexColumnAlignJustifyCenter style={{ marginBottom: "3.3rem", gap: "0.5rem" }}>
                <h1 className="heading5">{`Happy Valentines Day ${user?.Name || user?.username || ""}! <3`}</h1>
                <p className="body2" style={{ maxInlineSize: "50rem" }}>
                    Share this to your stories & tag @getknowt to get featured + enter our V-Day Giveaway for a free Kai
                    tumbler.
                </p>
            </FlexColumnAlignJustifyCenter>

            <FlexColumnAlignJustifyCenter style={{ gap: "1rem" }}>
                <CircularRectTextButton
                    onClick={async () => {
                        try {
                            await Mixpanel.track("Valentines Day Card Downloaded/Shared");
                            const image = `/images/2025-Valentines-2/${imageNumber}.png`;
                            const imageResponse = await fetch(image);
                            const imageBlob = await imageResponse.blob();
                            const imageURL = URL.createObjectURL(imageBlob);

                            const link = document.createElement("a");
                            link.href = imageURL;
                            link.download = "Knowt loves you <3";
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);

                            if (navigator?.share) {
                                await navigator
                                    .share({
                                        files: [new File([imageBlob], "valentines-card.png", { type: "image/png" })],
                                    })
                                    .catch();
                            }

                            toast.success("Card downloaded, share it with your loved ones!");
                        } catch {
                            toast.error("Something went wrong!");
                        }

                        onClose();
                    }}
                    style={{
                        width: "24.5rem",
                        height: "5.2rem",
                        fontSize: "1.8rem",
                        color: themeColors.primaryDark,
                        backgroundColor: themeColors.primary,
                    }}>
                    Share with someone
                </CircularRectTextButton>
            </FlexColumnAlignJustifyCenter>
        </FlexColumn>
    );

    return (
        <ResponsiveDialog
            isOpen={isOpen}
            onClose={onClose}
            style={{ alignItems: "center", justifyContent: "center", textAlign: "center" }}
            DialogStyle={{
                width: "60rem",
                padding: "3.2rem 3.2rem",
                borderRadius: "3rem",
                position: "relative",
                overflow: "hidden",
            }}
            DialogProps={{
                scroll: "body",
                classes: {
                    paper: "hide-scrollbar",
                },
            }}
            SlideMenuStyle={{ padding: "2rem" }}
            breakpoint="mdDown">
            {renderContent()}
            <div
                style={{
                    position: "fixed",
                    width: "100%",
                    height: "100%",
                    left: "50%",
                    top: "50%",
                    transform: "translateY(-70%)",
                    zIndex: 0,
                }}>
                <Confetti
                    config={{
                        spread: 360,
                        duration: 5000,
                        angle: 270,
                        colors: ["#ffc5e6", "#ff257e", "#ff2644", "#d60000"],
                        elementCount: 100,
                    }}
                />
            </div>
        </ResponsiveDialog>
    );
};

export default ValentinesDayPopup;

"use client";

import Dialog from "@/components/Dialog";
import { useBreakPoints } from "@/hooks/styles/useBreakpoints";
import LocalKeyValueStore from "@/utils/LocalKeyValueStore";
import { themeColors } from "@/utils/themeColors";
import { TIME } from "@knowt/syncing/utils/dateTimeUtils";
import { APP_STORE_URL, PLAY_STORE_URL, isDevelopment } from "@knowt/web/config/deployConstants";
import Image from "next/image";
import { useEffect, useState } from "react";
import { useLocation } from "react-use";
import { LocationSensorState } from "react-use/lib/useLocation";
import CircularRectTextButton from "./CircularButton/styled/CircularRectTextButton";
import { FlexColumn } from "./Flex";
import ClickableText from "./styled/button/ClickableText";

export const pathnameToDeepLink = (location: LocationSensorState) => {
    const { pathname, search } = location;
    if (!pathname) return "";

    const deeplinkPrefix = isDevelopment ? "knowtDev" : "knowt";

    switch (true) {
        case ["/home", "/settings", "/explore", "/folder", "/note", "/flashcards"].some(p => pathname.startsWith(p)):
            return deeplinkPrefix + ":/" + pathname;
        case pathname === "/exams":
            return deeplinkPrefix + "://allExams";
        case pathname.startsWith("/u/"):
            return deeplinkPrefix + ":/" + pathname;
        case pathname.startsWith("/class/join"):
            return deeplinkPrefix + ":/" + pathname + search;
        case pathname.startsWith("/exams/") && pathname.includes("/view/"):
            return deeplinkPrefix + "://viewExam/" + pathname.split("/")[4];
        case pathname.startsWith("/exams/") &&
            pathname.split("/")[3] &&
            ["free-response-room", "frq-room", "practice-test-room"].every(p => !pathname.includes(p)):
            // exam subject
            return deeplinkPrefix + "://examSubject/" + pathname.split("/")[3];
        case pathname.startsWith("/exams/") &&
            pathname.split("/")[2] &&
            ["free-response-room", "frq-room", "practice-test-room"].every(p => !pathname.includes(p)):
            // exam category
            return deeplinkPrefix + "://examCategory/" + pathname.split("/")[2];
        case pathname.startsWith("/class/") && pathname.includes("?tab=people"):
            return `${deeplinkPrefix}://class/${pathname.split("/")[2]}/people`;
        case pathname.startsWith("/class/") && pathname.includes("?tab=progress"):
            return `${deeplinkPrefix}://class/${pathname.split("/")[2]}/progress`;
        case pathname.startsWith("/class/") && pathname.includes("/dashboard"):
            return `${deeplinkPrefix}://class/${pathname.split("/")[2]}/progress`;
        default:
            return deeplinkPrefix + "://";
    }
};

const NEGATIVE_PATTERNS = [
    "/pdfviewer",
    "/login",
    "/signup",
    "/verify",
    "/reset",
    "/goodbye",
    "/",
    "/ai-video-summarizer",
    "/ai-pdf-summarizer",
    "/ai-powerpoint-summarizer",
    "/ai-spreadsheet-summarizer",
    "/ai-notes",
    "/teachers",
    "/schools",
    "/sso/login",
    "/sso/login/error",
];

const OpenInAppBanner = () => {
    const [isVisible, setIsVisible] = useState(false);
    const location = useLocation();
    const { smDown } = useBreakPoints();

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        const getValue = async () => {
            const lastShown = await LocalKeyValueStore.getWithExpiry("in-app-banner-last-shown");
            if (!lastShown && NEGATIVE_PATTERNS.every(p => location.pathname !== p)) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        getValue();
    }, []);

    useEffect(() => {
        if (NEGATIVE_PATTERNS.some(p => location.pathname === p)) {
            setIsVisible(false);
        }
    }, [location]);

    const setLastShown = () => {
        LocalKeyValueStore.setWithExpiry("in-app-banner-last-shown", TIME.HOUR * 6);
    };

    if (!isVisible || !smDown) {
        return null;
    }

    const handleClose = () => {
        setIsVisible(false);
        setLastShown();
    };

    const handleOpenApp = () => {
        setLastShown();
        const userAgent = navigator.userAgent;
        const deepLink = pathnameToDeepLink(location) as string & Location;
        let storeLink: string & Location;

        if (/android/i.test(userAgent)) {
            storeLink = PLAY_STORE_URL as string & Location;
        } else if (/iPad|iPhone|iPod/.test(userAgent)) {
            storeLink = APP_STORE_URL as string & Location;
        }

        window.location = deepLink;

        // Redirect to the store if the app doesn't open in 1 second
        const timeout = setTimeout(() => {
            window.location = storeLink;
        }, 1_000);

        window.onblur = () => clearTimeout(timeout);
    };

    return (
        <Dialog
            open={isVisible}
            onClose={handleClose}
            slotProps={{
                paper: {
                    style: {
                        backgroundColor: themeColors.background,
                        color: themeColors.neutralBlack,
                        boxShadow: "none",
                        borderRadius: "2rem 2rem 0 0",
                        maxWidth: "unset",
                        width: "100%",
                        position: "fixed",
                        maxHeight: "40rem",
                        flexDirection: "column",
                        padding: "4.2rem 2rem",
                        margin: "0",
                        alignItems: "center",
                        bottom: 0,
                        textAlign: "center",
                    },
                },
            }}>
            <Image
                src={"/images/app-icon.svg"}
                height={64}
                width={64}
                alt="app-icon"
                style={{ marginBottom: "0.8rem" }}
            />
            <div className="heading4" style={{ marginBottom: "0.8rem" }}>
                Open in the Knowt App
            </div>
            <div className="body1" style={{ marginBottom: "2.4rem" }}>
                Available on App and Play Stores
            </div>
            <FlexColumn style={{ width: "100%", gap: "1.2rem" }}>
                <CircularRectTextButton
                    onClick={handleOpenApp}
                    sx={{
                        backgroundColor: themeColors.primary,
                        color: themeColors.primaryDark,
                        fontSize: "2rem",
                        width: "100%",
                        borderRadius: "1rem",
                        padding: "1rem",
                    }}>
                    Open Knowt
                </CircularRectTextButton>
                <ClickableText
                    onClick={handleClose}
                    className="heading5"
                    style={{ color: themeColors.neutralBlack, margin: "0 auto" }}>
                    Not now
                </ClickableText>
            </FlexColumn>
        </Dialog>
    );
};

export default OpenInAppBanner;

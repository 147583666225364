import { iconSizes } from "@/utils/iconProps";

const StreakRevivalIcon = ({
    showWings,
    size = iconSizes.XL,
    ...props
}: { size?: number; showWings?: boolean } & React.SVGProps<SVGSVGElement>) => {
    return (
        <svg width={size} height={size} viewBox="0 0 34 29" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            {showWings && (
                <>
                    <path
                        d="M26.3901 7.51627C27.0509 8.63845 27.3972 9.91786 27.3927 11.22C27.3927 14.1735 25.4441 16.6991 23.2344 18.6157V20.4595C23.2344 21.6366 24.1886 22.5908 25.3655 22.5908H25.8335V23.4743C25.8335 24.4231 26.9641 25.1897 27.9126 25.1897H28.9522V27.2691C28.9522 28.1301 29.6504 28.8283 30.5117 28.8283C31.373 28.8283 32.071 28.1301 32.071 27.2691V13.754C32.071 10.1277 30.0054 7.53615 26.3901 7.51627Z"
                        fill="#F6DB6D"
                    />
                    <path
                        d="M7.60274 7.51627C6.94197 8.63845 6.59568 9.91786 6.60012 11.22C6.60012 14.1735 8.54896 16.6991 10.7587 18.6157V20.4595C10.7587 21.6366 9.80451 22.5908 8.62735 22.5908H8.15962V23.4743C8.15962 24.4231 7.02854 25.1897 6.08021 25.1897H5.04063V27.2691C5.04063 28.1301 4.34242 28.8283 3.48137 28.8283C2.62008 28.8283 1.92188 28.1301 1.92188 27.2691V13.754C1.92188 10.1277 3.98747 7.53615 7.60274 7.51627Z"
                        fill="#F6DB6D"
                    />
                </>
            )}

            <path
                d="M16.9976 22.5909C16.9976 22.5909 27.3937 18.0425 27.3937 11.22C27.3937 7.45196 24.6009 4.39755 21.1562 4.39755C19.3087 4.39755 18.1414 5.27592 16.9976 6.67163C15.8542 5.27592 14.6866 4.39755 12.8393 4.39755C9.39438 4.39755 6.60156 7.45196 6.60156 11.22C6.60156 18.0425 16.9976 22.5909 16.9976 22.5909Z"
                fill="#FF6C77"
            />

            {showWings && (
                <path
                    d="M9.75418 8.55563C9.55459 8.55563 9.37279 8.44168 9.28598 8.26198C9.1994 8.08228 9.22304 7.86889 9.34728 7.71259C10.1793 6.62434 11.4641 5.97738 12.8341 5.95679C13.1212 5.95679 13.3538 6.18937 13.3538 6.47646C13.3538 6.76356 13.1212 6.99637 12.8341 6.99637C11.7816 7.01813 10.7968 7.52002 10.1611 8.35932C10.0626 8.48333 9.91282 8.55563 9.75418 8.55563Z"
                    fill="#FE9189"
                />
            )}
            <path
                d="M8.91552 13.2342C8.68903 13.2337 8.48874 13.0863 8.42065 12.8701C8.24844 12.3369 8.15906 11.7802 8.15625 11.2199C8.15602 10.8127 8.1979 10.4065 8.28143 10.0083C8.31817 9.82486 8.45037 9.67534 8.62796 9.61661C8.80532 9.55788 9.00069 9.59906 9.13945 9.72424C9.2782 9.84966 9.33903 10.0399 9.29879 10.2224C9.22953 10.5504 9.19467 10.8846 9.19467 11.2199C9.19771 11.674 9.27048 12.1251 9.4104 12.5573C9.46024 12.7152 9.43169 12.8875 9.33365 13.0208C9.23585 13.1542 9.08001 13.233 8.91459 13.233L8.91552 13.2342Z"
                fill="#FE9189"
            />
            <path
                d="M19.0764 7.97597C18.8668 7.97691 18.6772 7.85173 18.5956 7.65846C18.5142 7.46542 18.5565 7.2422 18.7032 7.09245C19.3041 6.35892 20.2077 5.94032 21.1558 5.9567C21.4429 5.9567 21.6755 6.18928 21.6755 6.47638C21.6755 6.76347 21.4429 6.99629 21.1558 6.99629C20.489 6.98435 19.856 7.28923 19.4499 7.81803C19.3518 7.91911 19.2173 7.97597 19.0764 7.97597Z"
                fill="#FE9189"
            />
            {showWings && (
                <>
                    <path
                        d="M28.9494 25.7093C28.6623 25.7093 28.4297 25.4765 28.4297 25.1894V14.2736C28.4297 13.9865 28.6623 13.7537 28.9494 13.7537C29.2365 13.7537 29.4693 13.9865 29.4693 14.2736V25.1894C29.4693 25.4765 29.2365 25.7093 28.9494 25.7093Z"
                        fill="#F59F57"
                    />
                    <path
                        d="M25.8324 23.1103C25.5453 23.1103 25.3125 22.8777 25.3125 22.5906V18.9519C25.3125 18.6649 25.5453 18.432 25.8324 18.432C26.1195 18.432 26.3521 18.6649 26.3521 18.9519V22.5906C26.3521 22.8777 26.1195 23.1103 25.8324 23.1103Z"
                        fill="#F59F57"
                    />
                    <path
                        d="M5.04311 25.7093C4.75602 25.7093 4.52344 25.4765 4.52344 25.1894V14.2736C4.52344 13.9865 4.75602 13.7537 5.04311 13.7537C5.33021 13.7537 5.56302 13.9865 5.56302 14.2736V25.1894C5.56302 25.4765 5.33021 25.7093 5.04311 25.7093Z"
                        fill="#F59F57"
                    />
                    <path
                        d="M8.16053 23.1103C7.87344 23.1103 7.64062 22.8777 7.64062 22.5906V18.952C7.64062 18.6649 7.87344 18.4321 8.16053 18.4321C8.44763 18.4321 8.68021 18.6649 8.68021 18.952V22.5906C8.68021 22.8777 8.44763 23.1103 8.16053 23.1103Z"
                        fill="#F59F57"
                    />
                </>
            )}
        </svg>
    );
};

export default StreakRevivalIcon;

import swordPlusAnimation from "@/public/images/lottie/sword-plus-animation.json";
import { PlatformType, platform } from "@knowt/syncing/platform";
import { FlexRowAlignCenter } from "./components/Flex";
import { themeColors } from "./utils/themeColors";
import dynamic from "next/dynamic";

const Lottie = dynamic(() => import("lottie-react"), { ssr: false });

if (typeof window !== "undefined") {
    platform.configure({
        storage: () => import("@/utils/LocalKeyValueStore").then(m => m.default),
        toast: () =>
            import("react-hot-toast").then(m => ({
                ...m.toast,
                xpToast: (xp: number) =>
                    m.toast.custom(
                        <FlexRowAlignCenter
                            style={{
                                borderRadius: "2rem",
                                border: "1px solid #28C3E1",
                                padding: "1.2rem 2rem",
                                backgroundColor: themeColors.neutralBlack,
                                color: themeColors.neutralWhite,
                            }}>
                            <Lottie
                                animationData={swordPlusAnimation}
                                style={{
                                    height: 25,
                                    width: 25,
                                    marginRight: "0.5rem",
                                }}
                                loop={false}
                            />
                            {xp} XP
                        </FlexRowAlignCenter>,
                        {
                            duration: 4000,
                        }
                    ),
            })),

        timezone: () => import("@/utils/timezone").then(m => m.default),
        fetchFileBlob: () => import("@/utils/fileUtils").then(m => m.fetchFileBlob),
        anonymousViewerUid: () => import("@/utils/anonymousUserId").then(m => m.default),
        dataCleaning: async () => {
            const { getHtmlFromContent } = await import("@knowt/editor/helpers/getHtmlFromContent");
            const { getPlainTextFromContent } = await import("@knowt/editor/helpers/getPlainTextFromContent");
            return { getHtmlFromContent, getPlainTextFromContent };
        },
        fetch: async () => fetch,
        analytics: {
            mixpanel: () => import("@/utils/analytics/Mixpanel").then(m => m.default),
            logging: () => import("@/utils/analytics/logging"),
        },
        events: async () => ({
            on: typeof window !== "undefined" ? window.addEventListener : undefined,
            off: typeof window !== "undefined" ? window.removeEventListener : undefined,
        }),
        platformType: PlatformType.WEB,
    });
}

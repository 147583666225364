"use client";

import { Flex, FlexColumn, FlexColumnAlignJustifyCenter, FlexRowAlignCenter } from "@/components/Flex";
import LazyLoaded from "@/components/LazyLoaded";
import FreezeStreakIcon from "@/components/icons/FreezeStreakIcon";
import StreakDailyActivityIcon from "@/components/icons/StreakDailyActivityIcon";
import StreakIcon from "@/components/icons/StreakIcon";
import StreakPauseIcon from "@/components/icons/StreakPauseIcon";
import StreakRevivalIcon from "@/components/icons/StreakRevivalIcon";
import ResponsiveDialog from "@/features/ResponsiveDialog/ResponsiveDialog";
import DialogCloseBtn from "@/features/ResponsiveDialog/styled/DialogCloseBtn";
import { borderRadius } from "@/utils/borderRadius";
import { spacing } from "@/utils/spacing";
import { themeColors } from "@/utils/themeColors";
import { ActionEnum, DailyActivity } from "@knowt/syncing/graphql/schema";
import { useDailyActivities } from "@knowt/syncing/hooks/gamification/activities/useDailyActivity";
import { ExtraActionEnum, STREAK_ACTION_COLORS } from "@knowt/syncing/hooks/gamification/constants";
import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";
import { today } from "@knowt/syncing/utils/dateTimeUtils";
import dynamic from "next/dynamic";
import { useMemo, useState } from "react";
import { useGamificationProgressingContextSelector } from "../../Progressing/GamificationProgressingContext";
import StreaksCalendar from "../StreaksCalendar";
import HowToEarnButton from "@/features/NavBar/components/styled/HowToEarnButton";

const HowToEarn = dynamic(() => import("@/features/NavBar/components/gamification/metrics/HowToEarn"), { ssr: false });

const streakActions = [
    {
        icon: <StreakDailyActivityIcon size={28} />,
        action: ExtraActionEnum.STREAK,
        label: "Days Studied",
    },
    {
        icon: <StreakRevivalIcon showWings width={34} height={28} />,
        action: ActionEnum.STREAK_REVIVAL,
        label: "Days Revived",
    },
    {
        icon: <FreezeStreakIcon showPauseLine size={34} />,
        action: ActionEnum.STREAK_FREEZE,
        label: "Days Frozen",
    },
    {
        icon: <StreakPauseIcon showPauseLine size={40} />,
        action: ActionEnum.LONG_PAUSE,
        label: "Days Pausing",
    },
];

const HeaderStreakIcon = ({ action }: { action: ActionEnum | undefined | null }) => {
    if (!action) {
        return <StreakIcon size={40} isComplete={false} />;
    }

    switch (action) {
        case ActionEnum.STREAK_FREEZE:
            return <FreezeStreakIcon showPauseLine size={40} />;
        case ActionEnum.LONG_PAUSE:
            return <StreakPauseIcon showPauseLine size={40} />;
        case ActionEnum.STREAK_REVIVAL:
            return <StreakRevivalIcon showWings size={40} />;
        default:
            return <StreakIcon size={40} />;
    }
};

const PopupHeader = ({ onClose, dailyActivities }: { onClose: () => void; dailyActivities: DailyActivity[] }) => {
    const { user, tz } = useCurrentUser();

    const getActiveStreakAction = (): ActionEnum | undefined => {
        const todayDate = today(tz).format("YYYY-MM-DD");

        return dailyActivities?.find(activity => activity.activityDate === todayDate)?.streakAction ?? undefined;
    };

    const activeStreak = getActiveStreakAction();

    const currentHeaderColor = activeStreak
        ? STREAK_ACTION_COLORS[activeStreak] ?? STREAK_ACTION_COLORS[ExtraActionEnum.STREAK]
        : themeColors.neutral3;

    const [howToEarnOpen, setHowToEarnOpen] = useState(false);

    const handleHowToEarnOpen = () => {
        setHowToEarnOpen(true);
    };

    const handleHowToEarnClose = () => {
        setHowToEarnOpen(false);
    };

    return (
        <>
            <Flex
                style={{
                    backgroundColor: currentHeaderColor,
                    width: "100%",
                    padding: spacing.MD,
                    height: "18rem",
                }}>
                <DialogCloseBtn
                    onClick={onClose}
                    style={{
                        borderWidth: 0,
                        marginLeft: "auto",
                        marginBottom: "2rem",
                        color: themeColors.neutralBlack,
                        backgroundColor: themeColors.neutralWhite,
                    }}
                />
            </Flex>
            <FlexColumnAlignJustifyCenter
                style={{
                    width: "120%",
                    marginTop: "-13%",
                    borderRadius: "100%",
                    padding: "5rem 2rem",
                    position: "relative",
                    backgroundColor: themeColors.neutralWhite,
                }}>
                <FlexRowAlignCenter
                    style={{
                        backgroundColor: themeColors.neutralWhite,
                        gap: spacing.XS,
                        borderRadius: borderRadius.shortInput,
                        border: `1.5px solid ${themeColors.neutral1}`,
                        padding: "1rem 2.1rem",
                        position: "absolute",
                        top: "-33%",
                        marginTop: spacing.SM,
                    }}>
                    {/* <Image src={getStreakIcon(activeStreak)} alt="streak" width={45} height={45} /> */}
                    {/* {streakActions.find(({ action }) => action === activeStreak)?.icon} */}
                    <HeaderStreakIcon action={activeStreak} />
                    <span className="heading3" style={{ color: currentHeaderColor }}>
                        {user?.streak ?? 0}
                    </span>
                </FlexRowAlignCenter>
                <HowToEarnButton
                    title="Actions that earn a streak"
                    icon={<StreakIcon width={17} height={22} />}
                    onClick={handleHowToEarnOpen}
                    sx={{
                        marginBottom: spacing.SM,
                    }}
                />
            </FlexColumnAlignJustifyCenter>
            <LazyLoaded load={howToEarnOpen}>
                <HowToEarn isOpen={howToEarnOpen} onClose={handleHowToEarnClose} type={ExtraActionEnum.STREAK} />
            </LazyLoaded>
        </>
    );
};

const DEFAULT_AGGREGATED_DATA = {
    [ActionEnum.LONG_PAUSE]: 0,
    [ActionEnum.STREAK_FREEZE]: 0,
    [ActionEnum.STREAK_REVIVAL]: 0,
    [ExtraActionEnum.STREAK]: 0,
};

const PopupBottom = ({ month, dailyActivities }: { month: string; dailyActivities: DailyActivity[] }) => {
    const aggregatedData = useMemo(() => {
        const monthDailyActivities = dailyActivities?.filter(activity => activity.activityDate.split("-")[1] === month);

        if (!monthDailyActivities?.length) {
            return { ...DEFAULT_AGGREGATED_DATA };
        }

        return monthDailyActivities?.reduce(
            (acc, activity) => {
                if (!activity.streakAction) {
                    return acc;
                }

                if (activity.streakAction === ActionEnum.LONG_PAUSE) {
                    acc[ActionEnum.LONG_PAUSE] += 1;
                } else if (activity.streakAction === ActionEnum.STREAK_FREEZE) {
                    acc[ActionEnum.STREAK_FREEZE] += 1;
                } else if (activity.streakAction === ActionEnum.STREAK_REVIVAL) {
                    acc[ActionEnum.STREAK_REVIVAL] += 1;
                } else {
                    acc[ExtraActionEnum.STREAK] += 1;
                }

                return acc;
            },
            {
                ...DEFAULT_AGGREGATED_DATA,
            }
        );
    }, [dailyActivities, month]);

    return (
        <FlexRowAlignCenter style={{ flexWrap: "wrap", gap: spacing.XS, padding: spacing.MD }}>
            {streakActions.map(({ icon, action, label }) => (
                <FlexRowAlignCenter
                    key={action}
                    style={{
                        border: `1.5px solid ${themeColors.neutral1}`,
                        borderRadius: "2rem",
                        padding: `${spacing.SM} ${spacing.MD}`,
                        gap: spacing.XS_2,
                        minWidth: "49%",
                    }}>
                    {icon}
                    <FlexColumn>
                        <span className="bodyBold1">{aggregatedData[action] ?? 0}</span>
                        <span className="secondaryText1">{label}</span>
                    </FlexColumn>
                </FlexRowAlignCenter>
            ))}
        </FlexRowAlignCenter>
    );
};

const StreaksPopup = ({ isOpen, onClose: _onClose }: { isOpen: boolean; onClose?: () => void }) => {
    const { tz } = useCurrentUser();
    const { dailyActivities } = useDailyActivities({ numberOfDays: 1000 });

    const [month, setMonth] = useState(today(tz).format("MM"));

    const closeStreakStatusPopup = useGamificationProgressingContextSelector(state => state.closeStreakStatusPopup);

    const onClose = () => {
        closeStreakStatusPopup();
        _onClose?.();
    };

    const renderContent = () => {
        return (
            <FlexColumnAlignJustifyCenter>
                <PopupHeader onClose={onClose} dailyActivities={dailyActivities} />
                <FlexColumn
                    style={{
                        width: "100%",
                        zIndex: 1,
                        marginTop: "-6rem",
                        paddingInline: spacing.MD,
                        backgroundColor: themeColors.neutralWhite,
                    }}>
                    <StreaksCalendar
                        styles={{
                            calendar: {
                                "& .MuiDayCalendar-root": {
                                    minHeight: "32rem",
                                    padding: spacing.SM,
                                    borderRadius: borderRadius.card,
                                    border: `1.5px solid ${themeColors.neutral1}`,
                                },
                            },
                        }}
                        isHeaderControlOnRight
                        setMonth={setMonth}
                    />
                </FlexColumn>
                <PopupBottom dailyActivities={dailyActivities} month={month} />
            </FlexColumnAlignJustifyCenter>
        );
    };

    return (
        <ResponsiveDialog
            isOpen={isOpen}
            onClose={onClose}
            DialogStyle={{
                width: "80%",
                maxWidth: "50.2rem",
                height: "fit-content",
                borderRadius: "2rem",
                position: "relative",
                overflowX: "hidden",
            }}>
            {renderContent()}
        </ResponsiveDialog>
    );
};

export default StreaksPopup;

import { useEffect, useState } from "react";
import DomConfetti, { ConfettiConfig } from "react-dom-confetti";

const DEFAULT = {
    angle: 90,
    spread: 360,
    startVelocity: 40,
    elementCount: 113,
    dragFriction: 0.12,
    duration: 3000,
    stagger: 9,
    width: "10px",
    height: "10px",
    perspective: "691px",
    colors: ["#000", "#f00"],
} as ConfettiConfig;

export const Confetti = ({
    children,
    config: _config = DEFAULT,
}: {
    children?: React.ReactNode;
    config?: Partial<ConfettiConfig>;
}) => {
    const config = { ...DEFAULT, ..._config };

    const [completed, setCompleted] = useState(false);

    useEffect(() => {
        temporarilySetCompleted();
    }, []);

    const temporarilySetCompleted = () => {
        setCompleted(true);
        setTimeout(() => {
            setCompleted(false);
        }, 1000);
    };

    return (
        <>
            <div onClick={temporarilySetCompleted}>{children}</div>
            <DomConfetti active={completed} config={config} />
        </>
    );
};

export default Confetti;

"use client";

import { useStreakStatusMonitor } from "@/hooks/gamification/monitoring/streakMonitoring/useStreakStatusMonitor";

const StreakStatusMonitor = () => {
    useStreakStatusMonitor();
    return null;
};

export default StreakStatusMonitor;

"use client";

import { createContext, useContextSelector } from "@/utils/use-context-selector";
import { ReactNode, useCallback, useEffect, useRef, useState } from "react";
import {
    INITIAL_PROGRESSSING_POPUP_STATE,
    PopupState,
    PROGRESSING_POPUP_ORDER,
    progressingEvent,
    ProgressingEventMiscPayload,
    ProgressingPopupType,
    ProgressingType,
    StreakStatus,
} from "@/hooks/gamification/monitoring/progressing";
import { objectWithout } from "@/utils/dataCleaning";

export type GamificationProgressingContextType = {
    popupState: PopupState;
    openPopup: (popupType: ProgressingPopupType) => void;
    closePopup: (popupType: ProgressingPopupType) => void;
    isAPopupOpen: boolean;
    streakStatusPopupOpen: boolean;
    openStreakStatusPopup: () => void;
    closeStreakStatusPopup: () => void;
};

const GamificationProgressingContext = createContext<GamificationProgressingContextType | null>(null);

export const GamificationProgressingContextProvider = ({ children }: { children: ReactNode }) => {
    const popupsQueue = useRef<Partial<PopupState>>({});
    const [popupState, setPopupState] = useState<PopupState>(INITIAL_PROGRESSSING_POPUP_STATE);

    const isAPopupOpen = Object.values(popupState).some(popup => popup?.open);

    const [streakStatusPopupOpen, setStreakStatusPopupOpen] = useState(false);

    const isReadyToShowPopups = useRef(false);

    const openPopup = useCallback((popupType: ProgressingPopupType) => {
        const content = { ...popupsQueue.current[popupType] };
        setPopupState(prev => ({ ...prev, [popupType]: content }));
        popupsQueue.current = objectWithout(popupsQueue.current, popupType);
    }, []);

    const handleQueuedPopups = useCallback(() => {
        const nextPopupToOpen = PROGRESSING_POPUP_ORDER.find(popupType => popupsQueue.current[popupType]?.open);
        if (nextPopupToOpen) openPopup(nextPopupToOpen);
    }, [openPopup]);

    const closePopup = useCallback(
        (popupType: ProgressingPopupType) => {
            setPopupState(prev => ({ ...prev, [popupType]: { ...INITIAL_PROGRESSSING_POPUP_STATE[popupType] } }));
            handleQueuedPopups();
        },
        [handleQueuedPopups]
    );

    useEffect(() => {
        return progressingEvent.register(payload => {
            if (payload[ProgressingEventMiscPayload.READY_TO_SHOW_ALERTS]) {
                isReadyToShowPopups.current = true;
            }

            if (payload[StreakStatus.STREAK_ENCOURAGEMENT_POPUP]) {
                // popupsQueue.current[StreakStatus.STREAK_ENCOURAGEMENT_POPUP] = { open: true };
                // pass for now. chose to remove as there were too many popups
            }

            if (payload[StreakStatus.RESUME_LONG_PAUSE]) {
                const { daysRemaining } = payload[StreakStatus.RESUME_LONG_PAUSE];
                popupsQueue.current[StreakStatus.RESUME_LONG_PAUSE] = { open: true, daysRemaining };
            }

            if (payload[StreakStatus.LONG_PAUSE_ENDED]) {
                popupsQueue.current[StreakStatus.LONG_PAUSE_ENDED] = { open: true };
            }

            if (payload[StreakStatus.STREAK_FREEZES_WERE_USED]) {
                const { usedStreakFreezes, streak } = payload[StreakStatus.STREAK_FREEZES_WERE_USED];
                popupsQueue.current[StreakStatus.STREAK_FREEZES_WERE_USED] = { open: true, usedStreakFreezes, streak };
            }

            if (payload[StreakStatus.STREAK_BROKEN]) {
                popupsQueue.current[StreakStatus.STREAK_BROKEN] = { open: true };
            }

            if (payload[ProgressingType.STREAK]) {
                const { streak, dailyActivity } = payload[ProgressingType.STREAK];
                popupsQueue.current[ProgressingType.STREAK] = { open: true, streak, dailyActivity };
            }

            if (payload[ProgressingType.LEVEL]) {
                const { level, dailyActivity } = payload[ProgressingType.LEVEL];
                popupsQueue.current[ProgressingType.LEVEL] = { open: true, level, dailyActivity };
            }

            if (payload[ProgressingType.BADGES]) {
                const { badges, dailyActivity } = payload[ProgressingType.BADGES];
                popupsQueue.current[ProgressingType.BADGES] = { open: true, badges, dailyActivity };
            }

            if (isReadyToShowPopups.current) {
                handleQueuedPopups();
            }
        });
    }, [handleQueuedPopups]);

    const openStreakStatusPopup = useCallback(() => {
        setStreakStatusPopupOpen(true);
    }, []);

    const closeStreakStatusPopup = useCallback(() => {
        setStreakStatusPopupOpen(false);
    }, []);

    return (
        <GamificationProgressingContext.Provider
            value={{
                popupState,
                isAPopupOpen,
                openPopup,
                closePopup,
                streakStatusPopupOpen,
                openStreakStatusPopup,
                closeStreakStatusPopup,
            }}>
            {children}
        </GamificationProgressingContext.Provider>
    );
};

export const useGamificationProgressingContextSelector = <T,>(
    selector: (value: GamificationProgressingContextType) => T
) => useContextSelector(GamificationProgressingContext, selector);

"use client";

import { useEffect, useRef } from "react";
import { TIME } from "@/utils/dateTimeUtils";
import { useCommitViewedItems } from "@/hooks/views/useViewTracking";
import { useCurrentUser } from "@/hooks/user/useCurrentUser";

const ViewedItemsCommitter = () => {
    const { commitViewedItems, clearViewedItems } = useCommitViewedItems();
    const { userId } = useCurrentUser({ waitForFallbackData: true });

    const hasCommittedOnMount = useRef(false);

    useEffect(() => {
        if (!userId) return;

        if (!hasCommittedOnMount.current) {
            hasCommittedOnMount.current = true;

            setTimeout(() => {
                // delaying to not commit at the same time as we're adding
                commitViewedItems().then(() => clearViewedItems());
            }, (1 + Math.random()) * TIME.SECOND);
        }

        // commit viewed items every 5 minutes, and on blur
        const interval = setInterval(commitViewedItems, TIME.MINUTE * 5);
        window.addEventListener("blur", commitViewedItems);

        return () => {
            clearInterval(interval);
            window.removeEventListener("blur", commitViewedItems);
        };
    }, [userId, commitViewedItems, clearViewedItems]);

    return null;
};

export default ViewedItemsCommitter;

"use client";

import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";
import { SetState } from "@knowt/syncing/types/common";
import noop from "@knowt/syncing/utils/noop";
import { createContext, useContextSelector } from "@knowt/syncing/utils/use-context-selector";
import React, { useCallback, useMemo, useState } from "react";

type AiPrivacyContextValue = {
    isAiPrivacyPopupOpen: boolean;
    setIsAiPrivacyPopupOpen: SetState<boolean>;
    shouldDisplayAiPrivacyPopup: () => boolean;
    openAiInputMenu: () => void;
    setOpenAiInputMenu: SetState<() => void | Promise<void>>;
};

const AiPrivacyContext = createContext<AiPrivacyContextValue | null>(null);

/**
 * TODO: wrap this context for note and edit flashcard page only
 *  make "layout.tsx" on those file
 */
const AiPrivacyContextProvider = ({ children }: { children: React.ReactNode }) => {
    const { user } = useCurrentUser({ waitForFallbackData: true });

    const [isAiPrivacyPopupOpen, setIsAiPrivacyPopupOpen] = useState(false);
    const [openAiInputMenu, setOpenAiInputMenu] = useState(() => noop);

    const shouldDisplayAiPrivacyPopup = useCallback(() => {
        if (isAiPrivacyPopupOpen) {
            return false;
        }

        return !!user && !user.privacyAck;
    }, [isAiPrivacyPopupOpen, user]);

    const value = useMemo(
        () => ({
            isAiPrivacyPopupOpen,
            setIsAiPrivacyPopupOpen,
            shouldDisplayAiPrivacyPopup,
            openAiInputMenu,
            setOpenAiInputMenu,
        }),
        [isAiPrivacyPopupOpen, openAiInputMenu, shouldDisplayAiPrivacyPopup]
    );

    return <AiPrivacyContext.Provider value={value}>{children}</AiPrivacyContext.Provider>;
};

export default AiPrivacyContextProvider;

export const useAiPrivacyContextSelector = <T,>(selector: (value: AiPrivacyContextValue) => T) =>
    useContextSelector(AiPrivacyContext, selector);

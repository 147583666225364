import { ResourcesConfig } from "aws-amplify";
import { redirectSignIn, redirectSignOut } from "./deployConstants";

import { GRAPHQL_AUTH_MODE } from "@/utils/client/types";

const awsConfig: ResourcesConfig = {
    API: {
        GraphQL: {
            endpoint: "https://3gso5evnnzbr7l4ubazdtfa4wq.appsync-api.us-east-1.amazonaws.com/graphql",
            region: "us-east-1",
            defaultAuthMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
            apiKey: "iib4uixervgihoslp2yq274icy",
        },
    },
    Auth: {
        Cognito: {
            userPoolId: "us-east-1_m3PConctR",
            userPoolClientId: "2nd76e1v5lva4r1nfi3vku56rj",
            identityPoolId: "us-east-1:df33b2c7-a06b-46eb-b8d6-dd2972608bb0",
            allowGuestAccess: true,
            signUpVerificationMethod: "code",
            loginWith: {
                oauth: {
                    domain: "authprod.knowt.com",
                    scopes: ["phone", "email", "openid", "profile", "aws.cognito.signin.user.admin"],
                    redirectSignIn: [redirectSignIn],
                    redirectSignOut: [redirectSignOut],
                    responseType: "code",
                },
            },
        },
    },
};

export default awsConfig;

import { handleMaybeProgressing } from "@/hooks/gamification/monitoring/progressing";
import { isToday } from "@/hooks/gamification/monitoring/streakMonitoring/utils";
import { useCurrentUser } from "@/hooks/user/useCurrentUser";
import { useEffect, useRef } from "react";
import { callProcessAsyncActions } from "../activities/graphqlUtils";
import { useDailyActivities } from "../activities/useDailyActivity";

export const useProcessAsyncActions = () => {
    const { user, tz } = useCurrentUser();
    const { dailyActivities } = useDailyActivities({ numberOfDays: 7 });
    const hasProcessed = useRef(false);

    useEffect(() => {
        const asyncEffect = async () => {
            const lastDailyActivity = dailyActivities?.find(({ activityDate }) => isToday(activityDate, tz));

            // last daily activity might be null, if there's been no activity for today.
            if (lastDailyActivity?.asyncActions?.length) {
                const v2Response = await callProcessAsyncActions();
                await handleMaybeProgressing(v2Response, true);
            }
        };

        if (!user || !dailyActivities?.length) return;
        if (hasProcessed.current) return;
        hasProcessed.current = true;

        asyncEffect();
    }, [user, dailyActivities, tz]);
};

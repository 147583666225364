"use client";

import DelayedComponent from "@knowt/syncing/components/DelayedComponent";
import useMixpanelSetup from "@knowt/syncing/hooks/useMixpanelSetup";
import useSentrySetup from "@knowt/syncing/hooks/useSentrySetup";
import React from "react";

const DelayedSetup = () => {
    useMixpanelSetup();
    useSentrySetup();
    return null;
};

export default function LoggingSetup() {
    return (
        <DelayedComponent delay={500}>
            <DelayedSetup />
        </DelayedComponent>
    );
}

"use client";

import LazyLoaded from "@/components/LazyLoaded";
import { FlashcardSet, Media, Note } from "@knowt/syncing/graphql/schema";
import { createContext, useContextSelector } from "@knowt/syncing/utils/use-context-selector";
import dynamic from "next/dynamic";
import { useReducer } from "react";
import { TagFields } from "../types";

const TaggingPopup = dynamic(() => import("@/features/Tagging/components/TaggingPopup"));

type TaggableUserContent =
    | { note: Note; flashcardSet?: never; media?: never }
    | { flashcardSet: FlashcardSet; note?: never; media?: never }
    | { note?: never; flashcardSet?: never; media: Media };
type openTaggingPopupProps = TaggableUserContent & { onSave: (tags: TagFields) => Promise<void> };

type TaggingContextValue = {
    openTaggingPopup: (props: openTaggingPopupProps) => void;
    closeTaggingPopup: () => void;
};

const TaggingContext = createContext<TaggingContextValue | undefined>(undefined);

type OpenTaggingPopup = { type: "OPEN_TAGGING_POPUP"; payload: openTaggingPopupProps };
type CloseTaggingPopup = { type: "CLOSE_TAGGING_POPUP" };

type TaggingContextAction = OpenTaggingPopup | CloseTaggingPopup;
type TaggingContextStates = {
    isOpen: boolean;
    flashcardSet: FlashcardSet | null;
    note: Note | null;
    onSave: ((tags: TagFields) => Promise<void>) | null;
    media: Media | null;
};

const reducer = (state: TaggingContextStates, action: TaggingContextAction): TaggingContextStates => {
    switch (action.type) {
        case "OPEN_TAGGING_POPUP": {
            let newState = { ...state, isOpen: true, onSave: action.payload.onSave };
            if (action.payload.flashcardSet) {
                newState = { ...newState, flashcardSet: action.payload.flashcardSet };
            } else if (action.payload.note) {
                newState = { ...newState, note: action.payload.note };
            } else if (action.payload.media) {
                newState = { ...newState, media: action.payload.media };
            }
            return newState;
        }
        case "CLOSE_TAGGING_POPUP": {
            return { ...state, isOpen: false, flashcardSet: null, note: null, onSave: null, media: null };
        }
        default: {
            throw new Error(`Unhandled action type: ${action}`);
        }
    }
};

export const TaggingContextProvider = ({ children }: { children: React.ReactNode }) => {
    const [states, dispatch] = useReducer(reducer, {
        isOpen: false,
        onSave: null,
        flashcardSet: null,
        note: null,
        media: null,
    });

    const openTaggingPopup = (props: openTaggingPopupProps) => {
        dispatch({ type: "OPEN_TAGGING_POPUP", payload: props });
    };

    const closeTaggingPopup = () => {
        dispatch({ type: "CLOSE_TAGGING_POPUP" });
    };

    return (
        <TaggingContext.Provider value={{ openTaggingPopup, closeTaggingPopup }}>
            {children}
            <LazyLoaded load={states.isOpen}>
                <TaggingPopup
                    isOpen={states.isOpen}
                    onClose={closeTaggingPopup}
                    onSave={
                        states.onSave ||
                        (async () => {
                            throw new Error("No onSave function provided");
                        })
                    }
                    {...(states.flashcardSet
                        ? { flashcardSet: states.flashcardSet as FlashcardSet }
                        : states.note
                        ? { note: states.note as Note }
                        : {
                              media: states.media as Media,
                          })}
                />
            </LazyLoaded>
        </TaggingContext.Provider>
    );
};

export const useTaggingContextSelector = <T,>(selector: (value: TaggingContextValue) => T) =>
    useContextSelector(TaggingContext, selector);

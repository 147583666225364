import { AnyUserContent } from "@knowt/syncing/types/common";
import { isAssignment, isFlashcardSet, isFolder, isMedia, isNote } from "@knowt/syncing/utils/dataCleaning";
import { getNoteUrl } from "./url";

export const PREVIOUS_PATH_KEY = "prevPath";
export const CURRENT_PATH_KEY = "currentPath";

export const isOnHome = (pathname: string | null) => pathname === "/";

export const isOnFolder = (pathname: string | null) => /^\/folder\/[a-zA-Z0-9-]+$/.test(pathname || "");

// keep track of the current and previous paths
export const pathTrackerPlugin = (pathname: string | null) => {
    const storage = window.sessionStorage;

    if (storage) {
        storage.setItem(PREVIOUS_PATH_KEY, storage.getItem(CURRENT_PATH_KEY));
        storage.setItem(CURRENT_PATH_KEY, pathname);
    }
};

export const getPrevPath = () => {
    if (typeof window === "undefined") {
        return null;
    }

    return window.sessionStorage.getItem(PREVIOUS_PATH_KEY);
};

export const wasOnNotePage = () => {
    const prevPath = getPrevPath();
    if (!prevPath) return false;

    return prevPath.includes("/note/");
};

export const wasOnStandAloneFlashcardsPage = () => {
    const prevPath = getPrevPath()?.split("/")?.filter(Boolean);

    if (!prevPath) return false;

    return prevPath[0].includes("flashcards");
};

export const wasOnFlashcardsStudyPage = () => {
    const prevPath = getPrevPath();
    if (!prevPath) return false;

    return prevPath.includes("/study/flashcards/");
};

export const getPageDetailsFromPath = (path: string): { title: string; path: string }[] => {
    switch (true) {
        case path?.startsWith("/search"):
            return [
                { title: "Explore", path: "/explore" },
                { title: "Search", path },
            ];
        case path?.startsWith("/folder"):
            return [
                { title: "Home", path: "/" },
                { title: "Folder", path },
            ];
        case path?.startsWith("/subject"): {
            const cleanPath = path.replace(/%20/g, " ");
            const queryParamsIndex = cleanPath.indexOf("?");

            const pathList =
                queryParamsIndex > -1 ? cleanPath.slice(0, queryParamsIndex).split("/") : cleanPath.split("/");

            return [
                { title: "Explore", path: "/explore" },
                { title: pathList[2], path: pathList.slice(0, 3).join("/") },
                { title: pathList[3], path: pathList.join("/") },
            ];
        }
        case path?.includes("/edit") || path === "/":
            return [{ title: "Home", path: "/" }];
        case path?.startsWith("/u/"):
            return [{ title: "Profile", path }];
        case path?.startsWith("/course"):
            return [{ title: "Class", path }];
        default:
            return [{ title: "Explore", path: "/explore" }];
    }
};

export const getUserContentLink = (item: AnyUserContent) => {
    if (!item) return null;
    if (isNote(item)) return getNoteUrl({ noteId: item.noteId, title: item.title });
    if (isFlashcardSet(item)) return `/flashcards/${item.flashcardSetId}`;
    if (isMedia(item)) return `/media/${item.mediaId}`;
    if (isFolder(item)) return `/folder/${item.folderId}`;
    if (isAssignment(item)) return `/assignment/${item.assignmentId}`;

    throw new Error("getUserContentLink - invalid item type");
};

"use client";

import { ProgressingType } from "@/hooks/gamification/monitoring/progressing";
import { useGamificationProgressingContextSelector } from "./GamificationProgressingContext";
import dynamic from "next/dynamic";
import LazyLoaded from "@/components/LazyLoaded";
import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";
import { useMemo, useState } from "react";
import { useBadgeProgress } from "@knowt/syncing/hooks/gamification/badges/useBadges";
import { frontendBadgeMetadata } from "@knowt/syncing/hooks/gamification/badges/graphqlUtils";

const StreakProgressingPopup = dynamic(() => import("./StreakProgressingPopup"));
const LevelProgressingPopup = dynamic(() => import("./LevelProgressingPopup"));
const BadgeDetailsPopup = dynamic(() => import("@/features/Profile/BadgesPage/components/BadgeDetailsPopup"));

export const POPUP_DURATION = 10_000;

const GamificationProgressingPopups = () => {
    const { userId } = useCurrentUser();

    const isStreakProgressingPopupOpen = useGamificationProgressingContextSelector(
        state => state.popupState[ProgressingType.STREAK]?.open
    );

    const isLevelProgressingPopupOpen = useGamificationProgressingContextSelector(
        state => state.popupState[ProgressingType.LEVEL]?.open
    );

    const level = useGamificationProgressingContextSelector(state => state.popupState[ProgressingType.LEVEL]?.level);

    const isBadgeProgressingPopupOpen = useGamificationProgressingContextSelector(
        state => state.popupState[ProgressingType.BADGES]?.open
    );

    const { badgeProgresses } = useBadgeProgress();

    const badges = useGamificationProgressingContextSelector(state => state.popupState[ProgressingType.BADGES]?.badges);
    const closePopup = useGamificationProgressingContextSelector(state => state.closePopup);

    const [currentShowingBadgeIndex, setCurrentShowingBadgeIndex] = useState(0);

    const badge = useMemo(
        () => frontendBadgeMetadata.find(badge => badge.badge === badges?.[currentShowingBadgeIndex]?.badge),
        [badges, currentShowingBadgeIndex]
    );

    const badgeProgress = useMemo(
        () => badgeProgresses?.find(badgeProgress => badgeProgress.badge === badge?.badge),
        [badge, badgeProgresses]
    );

    return (
        <>
            <LazyLoaded load={isStreakProgressingPopupOpen}>
                <StreakProgressingPopup />
            </LazyLoaded>
            <LazyLoaded load={isLevelProgressingPopupOpen}>
                {/* add key so that it rerenders for each new level */}
                <LevelProgressingPopup key={level} />
            </LazyLoaded>
            <LazyLoaded load={isBadgeProgressingPopupOpen}>
                <BadgeDetailsPopup
                    key={badge?.badge}
                    isOpen={isBadgeProgressingPopupOpen}
                    onClose={() => {
                        if (currentShowingBadgeIndex < badges.length - 1) {
                            return setCurrentShowingBadgeIndex(currentShowingBadgeIndex + 1);
                        }

                        closePopup(ProgressingType.BADGES);
                        setCurrentShowingBadgeIndex(0);
                    }}
                    badge={badge}
                    badgeProgress={badgeProgress}
                    profileId={userId}
                    closeOnClaim
                />
            </LazyLoaded>
        </>
    );
};

export default GamificationProgressingPopups;

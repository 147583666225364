import CircularRectTextButton from "@/components/CircularButton/styled/CircularRectTextButton";
import { FlexColumnAlignJustifyCenter, FlexRowAlignCenter } from "@/components/Flex";
import { spacing } from "@/utils/spacing";
import { themeColors } from "@/utils/themeColors";
import { SxProps } from "@mui/material/styles";
import React, { ReactElement } from "react";

type Props = {
    icon?: ReactElement<any>;
    title: string;
    onClick?: () => void;
    sx?: SxProps;
};

const HowToEarnButton: React.FC<Props> = ({ icon, title, onClick, sx }) => {
    return (
        <CircularRectTextButton
            onClick={onClick}
            sx={{
                gap: spacing.XS,
                cursor: "pointer",
                border: `1px solid ${themeColors.neutralBlack}`,
                color: themeColors.neutralBlack,
                backgroundColor: themeColors.neutralWhite,
                borderRadius: "5rem",
                padding: `${spacing.XS} ${spacing.LG}`,
                "&:active": {
                    transform: "scale(0.99)",
                },
                ...sx,
            }}>
            <FlexColumnAlignJustifyCenter>
                <FlexRowAlignCenter
                    style={{
                        gap: spacing.XS,
                    }}>
                    <p className="bodyBold2">{title}</p>
                    {icon}
                </FlexRowAlignCenter>
            </FlexColumnAlignJustifyCenter>
        </CircularRectTextButton>
    );
};

export default HowToEarnButton;

"use client";

import LazyLoaded from "@/components/LazyLoaded";
import { showValentinesDayPopup } from "@/components/Popup/ValentinesDayPopup";
import { useDialogContextSelector } from "@/contexts/DialogContext";
import { useMultiTabsEditingWarningContextSelector } from "@/contexts/MultiTabsEditingWarningContext";
import { useAuthPopupContextSelector } from "@/features/Auth/AuthPopup/hook/useAuthPopupContext";
import { useAuthSlideContextSelector } from "@/features/Auth/AuthSlide/hook/useAuthSlideContext";
import { useGamificationProgressingContextSelector } from "@/features/Gamification/Progressing/GamificationProgressingContext";
import GamificationProgressingPopups from "@/features/Gamification/Progressing/GamificationProgressingPopups";
import ProcessAsyncActions from "@/features/Gamification/Progressing/ProcessAsyncActions";
import StreakStatusMonitor from "@/features/Gamification/Streak/StreakStatusMonitor";
import StreaksPopup from "@/features/Gamification/components/popups/StreaksPopup";
import { useUpgradePopupContextSelector } from "@/features/Payments/PaywallPopup/UpgradePopupContext";
import { StreakStatus } from "@/hooks/gamification/monitoring/progressing";
import { useAiPrivacyContextSelector } from "@/hooks/useAiPrivacyContextSelector";
import packageInfo from "@/package.json";
import { themeColors } from "@/utils/themeColors";
import dynamic from "next/dynamic";
import { useEffect } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { AuthHubListener } from "./AuthHubListener";

const PaywallPopup = dynamic(() => import("@/features/Payments/PaywallPopup/PaywallPopup"));

const MultiTabsEditingWarningPopup = dynamic(
    () => import("@/components/Popup/MultiTabsEditingWarningPopup/MultiTabsEditingWarningPopup")
);

const Toaster = dynamic(() => import("react-hot-toast").then(m => m.Toaster));
const AiPrivacyPopup = dynamic(() => import("@/features/AiPopup/components/AiPrivacyPopup"));

const AuthPopup = dynamic(() => import("@/features/Auth/AuthPopup/AuthPopup"));
const AuthSlideDialog = dynamic(() => import("@/features/Auth/AuthSlide/AuthSlideDialog"));
const ConfirmDialog = dynamic(() => import("@/components/Popup/ConfirmDialog"));

const StreakBrokenPopup = dynamic(() => import("@/features/Gamification/Streak/StreakBrokenPopup"));
const StreakFreezesWereUsedPopup = dynamic(() => import("@/features/Gamification/Streak/StreakFreezesWereUsedPopup"));
const LongPauseIsOverPopup = dynamic(() => import("@/features/Gamification/Streak/LongPauseIsOverPopup"));
const ValentinesDayPopup = dynamic(() => import("@/components/Popup/ValentinesDayPopup"));

const StreakEncouragementPopup = dynamic(
    () => import("@/features/Gamification/components/popups/StreakEncouragementPopup")
);

const EndLongPausePopup = dynamic(() => import("@/features/Gamification/Streak/EndLongPausePopup"));

// NOTE: sometimes our page is unscrollable for some reason
// for now we assume that there is some ads that modify the body overflow for some reason. This trick is to set the body scroll back to auto
const ObserveBlockedBodyScroll = () => {
    useEffect(() => {
        const body = document.body;

        const observer = new MutationObserver((mutationList, _observer) => {
            for (const mutation of mutationList) {
                if (mutation.attributeName === "style") {
                    if (body.style.overflow !== "auto") {
                        body.style.overflow = "auto";
                    }
                }
            }
        });

        observer.observe(body, { attributes: true });

        return () => observer.disconnect();
    }, []);

    return null;
};

export default function TopLevelComponents() {
    useEffect(() => {
        // biome-ignore lint/suspicious/noConsole:
        console.log(`You are running Knowt v.${packageInfo.version}`);
    }, []);

    const isAuthSlideOpen = useAuthSlideContextSelector(state => state.authSlideStates?.isOpen);
    const isAuthPopupOpen = useAuthPopupContextSelector(state => state.authPopupStates?.isOpen);
    const isConfirmDialogOpen = useDialogContextSelector(state => state.confirmDialog.open);
    const isAiPrivacyPopupOpen = useAiPrivacyContextSelector(state => state.isAiPrivacyPopupOpen);
    const isUpgradePopupOpen = useUpgradePopupContextSelector(state => state.isOpen);
    const isMultiTabsEditingWarningOpen = useMultiTabsEditingWarningContextSelector(state => state.isOpen);

    const isStreakFreezesWereUsedPopupOpen = useGamificationProgressingContextSelector(
        state => state.popupState[StreakStatus.STREAK_FREEZES_WERE_USED]?.open
    );

    const isStreakBrokenPopupOpen = useGamificationProgressingContextSelector(
        state => state.popupState[StreakStatus.STREAK_BROKEN]?.open
    );

    const isStreakEncouragementPopupOpen = useGamificationProgressingContextSelector(
        state => state.popupState[StreakStatus.STREAK_ENCOURAGEMENT_POPUP]?.open
    );

    const isLongPauseIsOverPopupOpen = useGamificationProgressingContextSelector(
        state => state.popupState[StreakStatus.LONG_PAUSE_ENDED]?.open
    );

    const isEndLongPausePopupOpen = useGamificationProgressingContextSelector(
        state => state.popupState[StreakStatus.RESUME_LONG_PAUSE]?.open
    );

    const streakStatusPopupOpen = useGamificationProgressingContextSelector(state => state.streakStatusPopupOpen);

    return (
        <>
            <ObserveBlockedBodyScroll />
            <AuthHubListener />

            <LazyLoaded load={isMultiTabsEditingWarningOpen}>
                <MultiTabsEditingWarningPopup />
            </LazyLoaded>
            <LazyLoaded load={showValentinesDayPopup()}>
                <ValentinesDayPopup />
            </LazyLoaded>
            <LazyLoaded load={isConfirmDialogOpen}>
                <ConfirmDialog />
            </LazyLoaded>
            <LazyLoaded load={isAuthPopupOpen}>
                <AuthPopup />
            </LazyLoaded>
            <LazyLoaded load={isUpgradePopupOpen}>
                <PaywallPopup />
            </LazyLoaded>
            <LazyLoaded load={isAiPrivacyPopupOpen}>
                <AiPrivacyPopup />
            </LazyLoaded>
            <LazyLoaded load={isAuthSlideOpen}>
                <GoogleReCaptchaProvider reCaptchaKey="6LfT_R4oAAAAAG-ZcqjXS6luIzLBWsdN0Mw2Mb6y">
                    <AuthSlideDialog />
                </GoogleReCaptchaProvider>
            </LazyLoaded>
            <GamificationProgressingPopups />
            <StreakStatusMonitor />
            <ProcessAsyncActions />
            <LazyLoaded load={isStreakFreezesWereUsedPopupOpen}>
                <StreakFreezesWereUsedPopup isOpen={isStreakFreezesWereUsedPopupOpen} />
            </LazyLoaded>
            <LazyLoaded load={isStreakBrokenPopupOpen}>
                <StreakBrokenPopup isOpen={isStreakBrokenPopupOpen} />
            </LazyLoaded>
            <LazyLoaded load={isStreakEncouragementPopupOpen}>
                <StreakEncouragementPopup isOpen={isStreakEncouragementPopupOpen} />
            </LazyLoaded>
            <LazyLoaded load={isLongPauseIsOverPopupOpen}>
                <LongPauseIsOverPopup isOpen={isLongPauseIsOverPopupOpen} />
            </LazyLoaded>
            <LazyLoaded load={isEndLongPausePopupOpen}>
                <EndLongPausePopup isOpen={isEndLongPausePopupOpen} />
            </LazyLoaded>
            <LazyLoaded load={streakStatusPopupOpen}>
                <StreaksPopup isOpen={streakStatusPopupOpen} />
            </LazyLoaded>
            <Toaster
                position="top-center"
                reverseOrder
                containerStyle={{
                    zIndex: 999_999,
                }}
                toastOptions={{
                    style: {
                        backgroundColor: themeColors.neutralBlack,
                        color: themeColors.neutralWhite,
                        maxWidth: "50rem",
                    },
                    error: { duration: 5_000, iconTheme: { primary: "#f18888", secondary: themeColors.pureWhite } },
                    loading: { iconTheme: { primary: "#50d2c2", secondary: themeColors.pureWhite } },
                    success: { duration: 5_000, iconTheme: { primary: "#50d2c2", secondary: themeColors.pureWhite } },
                }}
            />
        </>
    );
}

import { useEffect } from "react";
import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";
import { platform } from "@/platform";

const useSentrySetup = () => {
    const { userId, user } = useCurrentUser();

    useEffect(() => {
        const asyncSetup = async () => {
            const logging = await platform.analytics.logging();

            if (userId) {
                logging.setUser(user);
            } else {
                logging.setUser(null);
            }
        };

        asyncSetup();
    }, [user, userId]);
};

export default useSentrySetup;

import { FlashcardSet, ItemType, Media, TemporaryViewItemInput } from "@/graphql/schema";
import { useCurrentUser } from "@/hooks/user/useCurrentUser";
import { callViewItems } from "@/hooks/views/graphqlUtils";
import { useRecentlyViewed } from "@/hooks/views/useRecentlyViewed";
import { platform } from "@/platform";
import { NoteMetadata } from "@/types/common";
import { deepScrapeEmptyFields } from "@/utils/dataCleaning";
import { TIME_SECONDS, now } from "@/utils/dateTimeUtils";
import { unionBy } from "lodash-es";
import { useCallback, useEffect, useRef } from "react";

export const VIEWED_BUFFER_KEY = "viewedBuffer";

export type StoredViewedItems = Array<
    TemporaryViewItemInput & {
        viewedAt: number;
        committed: boolean;
    }
>;

export const useTrackView = ({
    itemId,
    itemType,
    data,
    canTrack = () => true,
}: Omit<TemporaryViewItemInput, "itemOwnerId"> & {
    data: NoteMetadata | FlashcardSet | Media | null | undefined;
    canTrack?: () => boolean;
}) => {
    const { add: addToRecentlyViewed, isLoadingRecentlyViewed } = useRecentlyViewed();
    const { userId, viewerId } = useCurrentUser();

    const hasTrackedRef = useRef(false);

    const addToViewedBuffer = useCallback(
        async ({ itemId, itemType, itemOwnerId }: { itemId: string; itemType: ItemType; itemOwnerId: string }) => {
            const storage = await platform.storage();

            const storedViewedItems = JSON.parse((await storage.get(VIEWED_BUFFER_KEY)) ?? "[]") as StoredViewedItems;
            const existingStoredItem = storedViewedItems.reverse().find(item => item.itemId === itemId);

            const wasNotSeenWithinLastMinute =
                !existingStoredItem ||
                (!existingStoredItem.committed && now() - existingStoredItem.viewedAt > TIME_SECONDS.MINUTE);

            if (wasNotSeenWithinLastMinute) {
                const newViewedItem = { itemId, itemType, viewedAt: now(), itemOwnerId, committed: false };
                await storage.set(
                    VIEWED_BUFFER_KEY,
                    JSON.stringify(unionBy(storedViewedItems, [newViewedItem], "itemId"))
                );
            }
        },
        []
    );

    useEffect(() => {
        const asyncEffect = async () => {
            if (
                hasTrackedRef.current ||
                !itemId ||
                !itemType ||
                !data ||
                isLoadingRecentlyViewed ||
                !canTrack() ||
                (!userId && !viewerId)
            ) {
                return;
            }

            hasTrackedRef.current = true;

            await addToRecentlyViewed({
                itemId,
                itemType,
                data: deepScrapeEmptyFields(data),
                itemOwnerId: data.userId,
            });

            if (userId !== data.userId) {
                await addToViewedBuffer({ itemId, itemType, itemOwnerId: data.userId });
            }
        };

        asyncEffect();
    }, [
        userId,
        viewerId,
        itemId,
        itemType,
        data,
        isLoadingRecentlyViewed,
        canTrack,
        addToViewedBuffer,
        addToRecentlyViewed,
    ]);
};

export const useCommitViewedItems = () => {
    const isCommittingRef = useRef(false);

    const commitViewedItemsImpl = useCallback(async () => {
        const storage = await platform.storage();
        const viewedItems = JSON.parse((await storage.get(VIEWED_BUFFER_KEY)) || "[]") as StoredViewedItems;
        const badData = new Set(viewedItems.filter(item => !item.itemId || !item.itemType || !item.itemOwnerId));

        if (badData.size) {
            // remove items with bad data
            await storage.set(VIEWED_BUFFER_KEY, JSON.stringify(viewedItems.filter(item => !badData.has(item))));
        }

        const parsedItems = viewedItems.filter(item => !item.committed && !badData.has(item));

        if (!parsedItems.length) {
            // only commit if there are items to commit
            return;
        }

        await callViewItems({
            viewedItems: parsedItems.map(({ itemId, itemType, itemOwnerId, classId }) => ({
                itemId,
                itemType,
                itemOwnerId,
                classId,
            })),
        });

        await storage.set(VIEWED_BUFFER_KEY, JSON.stringify(viewedItems.map(item => ({ ...item, committed: true }))));
    }, []);

    const commitViewedItems = useCallback(async () => {
        if (!isCommittingRef.current) {
            isCommittingRef.current = true;

            try {
                await commitViewedItemsImpl();
            } finally {
                isCommittingRef.current = false;
            }
        }
    }, [commitViewedItemsImpl]);

    const clearViewedItems = useCallback(async () => {
        const storage = await platform.storage();
        await storage.remove(VIEWED_BUFFER_KEY);
    }, []);

    return { commitViewedItems, clearViewedItems };
};

import { useEffect, useRef } from "react";
import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";
import { isUnderAge } from "@knowt/syncing/hooks/user/utils";
import { platform } from "@/platform";

const useMixpanelSetup = () => {
    const { userId, user } = useCurrentUser({ waitForFallbackData: true });
    const isInitalized = useRef(false);

    useEffect(() => {
        const asyncSetup = async () => {
            const mixpanel = await platform.analytics.mixpanel();
            if (user) {
                if (!isInitalized.current) {
                    if (isUnderAge(user?.birthday, 13)) {
                        // respect DNT now
                        mixpanel.init(false);
                    } else {
                        mixpanel.init(true);
                    }
                }

                isInitalized.current = true;
                mixpanel.identify(userId);
                mixpanel.people.set({ ...user, $email: user?.Email, $name: user?.Name });
            }
        };

        asyncSetup();
    }, [userId, user]);
};

export default useMixpanelSetup;

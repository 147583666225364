import { Assignment } from "@knowt/syncing/graphql/schema";
import { AssignmentMetadata } from "@/types/common";
import { RawFlashcardSet } from "@/graphql/customSchema";
import { useLocalStorageSWR } from "@/hooks/swr/localStorage";
import { NoteMetadata } from "@/types/common";
import { UNTITLED } from "@/utils/dataCleaning";
import { timeDeltaFromNow } from "@/utils/dateTimeUtils";
import { Media, TemporaryView } from "@knowt/syncing/graphql/schema";
import { unionBy } from "lodash-es";
import { useCallback, useMemo } from "react";
import { useCurrentUser } from "../user/useCurrentUser";
import { SuggestedHomeSearchItem } from "../userFiles/useLocalSearchSuggestions";

type Data = NoteMetadata | RawFlashcardSet | AssignmentMetadata | Media;

export type RecentlyViewedItem = Omit<TemporaryView, "count" | "__typename"> & { data: Data };

const CACHE_LENGTH = 25;

export const useRecentlyViewed = ({ length = CACHE_LENGTH }: { length?: number } = {}) => {
    const { userId } = useCurrentUser();

    const {
        data: _data,
        set,
        isLoading: isLoadingRecentlyViewed,
        mutate,
    } = useLocalStorageSWR<RecentlyViewedItem[]>(`${userId}-recentlyViewed`);

    const add = useCallback(
        async (input: Pick<TemporaryView, "itemOwnerId" | "itemId" | "itemType"> & { data: Data }) => {
            if (isLoadingRecentlyViewed) return;

            return await set(prevData => {
                const newRecord: RecentlyViewedItem = { ...input, viewTime: Date.now() };
                return unionBy([newRecord, ...(prevData ?? [])], "itemId").slice(0, CACHE_LENGTH);
            });
        },
        [set, isLoadingRecentlyViewed]
    );

    const reset = useCallback(() => set(null), [set]);

    const remove = useCallback(
        async (itemIds: string[]) => {
            const uniqueIds = new Set(itemIds);
            await set(prevData => prevData?.filter(({ itemId }) => !uniqueIds.has(itemId)) ?? []);
        },
        [set]
    );

    const recentlyViewed = useMemo(() => {
        if (!_data) return null;

        return (
            _data
                .map(({ data, ...view }) => {
                    return {
                        ...view,
                        ...data,
                        id: view.itemId,
                        // so that we can show PDF/Video icons in recently viewed
                        type: (data as Assignment).type ?? (data as Media).type ?? view.itemType,
                        title: data?.title || UNTITLED,
                        description: `Viewed ${timeDeltaFromNow(view.viewTime)} ago`,
                    };
                })
                .filter(Boolean) as SuggestedHomeSearchItem[]
        ).slice(0, length);
    }, [_data, length]);

    return { recentlyViewed, add, reset, remove, isLoadingRecentlyViewed, mutate };
};

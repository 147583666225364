"use client";
import React, { useCallback, useEffect, useState } from "react";
import { createContext, useContextSelector } from "@knowt/syncing/utils/use-context-selector";
import noop from "@/utils/noop";
import { usePathname } from "next/navigation";
import { useCurrentUser } from "@/hooks/user/useCurrentUser";
import { fetchNoteMetadata } from "@/hooks/notes/graphqlUtils";

type MultiTabsEditingWarningContextType = {
    isOpen: boolean;
    flashcardSetId: string | null;
    noteId: string | null;
    openMultiTabsEditingWarning: () => void;
    openMultiTabsNoteEditingWarningForOwners: (noteId: string) => void;
    closeMultiTabsEditingWarning: () => void;
};

const MultiTabsEditingWarningContext = createContext<MultiTabsEditingWarningContextType>({
    isOpen: false,
    flashcardSetId: null,
    noteId: null,
    openMultiTabsEditingWarning: noop,
    openMultiTabsNoteEditingWarningForOwners: noop,
    closeMultiTabsEditingWarning: noop,
});

const FLASHCARDS_EDITING_ROUTE_REGEX = /^\/flashcards\/[^/]+\/edit$/;
const NOTE_EDITING_ROUTE_REGEX = /^\/note\/[^/]+\/[^/]+$/;

const flashcardSetIdFromPathname = (pathname: string) => pathname.split("/")[2];
const noteIdFromPathname = (pathname: string) => pathname.split("/")[2];

const MultiTabsEditingWarningContextProvider = ({ children }: { children: React.ReactNode }) => {
    const { userId, loginInProgress } = useCurrentUser({
        waitForFallbackData: true,
    });

    const pathname = usePathname();

    const [isOpen, setIsOpen] = useState(false);
    const [flashcardSetId, setFlashcardSetId] = useState<string | null>(null);
    const [noteId, setNoteId] = useState<string | null>(null);

    const openMultiTabsEditingWarning = useCallback(() => {
        setIsOpen(true);
    }, []);

    const openMultiTabsNoteEditingWarningForOwners = useCallback(
        async (noteId: string) => {
            const noteMetadata = await fetchNoteMetadata({ noteId });
            if (noteMetadata && noteMetadata.userId === userId) {
                openMultiTabsEditingWarning();
            }
        },
        [openMultiTabsEditingWarning, userId]
    );

    const closeMultiTabsEditingWarning = useCallback(() => {
        setIsOpen(false);
    }, []);

    useEffect(() => {
        // not interested in checking for multi-tabs editing warning if user
        // is not yet logged in, since only logged-in users can edit.
        if (loginInProgress || !userId) return;

        if (typeof BroadcastChannel === "undefined") return;

        const interTabsCurrentEditingRouteChannel = new BroadcastChannel("inter-tabs-current-editing-route-channel");

        const interTabsCurrentEditingRouteChannelMessageHandler = async ({ data: message }: MessageEvent) => {
            if (message.pathname !== pathname) return;

            if (message.shouldReply) {
                interTabsCurrentEditingRouteChannel.postMessage({
                    pathname,
                    type: message.type,
                    shouldReply: false,
                });
            }

            if (message.type === "flashcard-set") {
                openMultiTabsEditingWarning();
            } else if (message.type === "note") {
                await openMultiTabsNoteEditingWarningForOwners(noteIdFromPathname(pathname));
            }
        };

        interTabsCurrentEditingRouteChannel.addEventListener(
            "message",
            interTabsCurrentEditingRouteChannelMessageHandler
        );

        const isFlashcardsEditingRoute = FLASHCARDS_EDITING_ROUTE_REGEX.test(pathname);
        const isNoteEditingRoute = NOTE_EDITING_ROUTE_REGEX.test(pathname);

        if (isFlashcardsEditingRoute) {
            interTabsCurrentEditingRouteChannel.postMessage({
                pathname,
                type: "flashcard-set",
                shouldReply: true,
            });
            setFlashcardSetId(flashcardSetIdFromPathname(pathname));
        } else if (isNoteEditingRoute) {
            interTabsCurrentEditingRouteChannel.postMessage({ pathname, type: "note", shouldReply: true });
            setNoteId(noteIdFromPathname(pathname));
        } else {
            closeMultiTabsEditingWarning();
            setFlashcardSetId(null);
            setNoteId(null);
        }

        return () => {
            interTabsCurrentEditingRouteChannel.removeEventListener(
                "message",
                interTabsCurrentEditingRouteChannelMessageHandler
            );
            interTabsCurrentEditingRouteChannel.close();
        };
    }, [
        userId,
        loginInProgress,
        pathname,
        openMultiTabsEditingWarning,
        closeMultiTabsEditingWarning,
        openMultiTabsNoteEditingWarningForOwners,
    ]);

    return (
        <MultiTabsEditingWarningContext.Provider
            value={{
                isOpen,
                flashcardSetId,
                noteId,
                openMultiTabsEditingWarning,
                openMultiTabsNoteEditingWarningForOwners,
                closeMultiTabsEditingWarning,
            }}>
            {children}
        </MultiTabsEditingWarningContext.Provider>
    );
};

export const useMultiTabsEditingWarningContextSelector = <T,>(
    selector: (context: MultiTabsEditingWarningContextType) => T
) => useContextSelector(MultiTabsEditingWarningContext, selector);

export default MultiTabsEditingWarningContextProvider;

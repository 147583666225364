"use client";

import { usePathname, useSearchParams } from "next/navigation";
import Script from "next/script";
import { useEffect } from "react";
import { pageview } from "./gTagHelper";
import { pathTrackerPlugin } from "@/utils/pathUtils";
import { GA4_MEASUREMENT_ID } from "@/config/deployConstants";

const GoogleAnalytics = () => {
	const pathname = usePathname();
	const searchParams = useSearchParams();

	useEffect(() => {
		const url = pathname + (searchParams?.toString() || "");

		pageview(url);
		pathTrackerPlugin(url);
	}, [
		pathname,
		searchParams,
	]);

	return (
		<>
			<Script
				src={`https://www.googletagmanager.com/gtag/js?id=${GA4_MEASUREMENT_ID}`}
				strategy="afterInteractive"
			/>
			<Script id="google-analytics" strategy="afterInteractive">
				{`
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());

                    gtag('config', '${GA4_MEASUREMENT_ID}');
                `}
			</Script>
		</>
	);
};

export default GoogleAnalytics;

"use client";

import dynamic from "next/dynamic";
import { ReactNode, useState } from "react";
import { createContext, useContextSelector } from "@knowt/syncing/utils/use-context-selector";
import LazyLoaded from "@/components/LazyLoaded";

const ContentPreviewerPopup = dynamic(() => import("@/components/ContentPreviewer/ContentPreviewerPopup"));

type WithNoteId = { noteId: string; flashcardSetId?: never };
type WithFlashcardSetId = { noteId?: never; flashcardSetId?: string };

type CardPreviewContextValue = {
    isOpen: boolean;
    noteId: string | null;
    flashcardSetId: string | null;
    openCardPreview: (props: WithNoteId | WithFlashcardSetId) => void;
    closeCardPreview: () => void;
    isCurrentlyInView: (props: { noteId?: string | null; flashcardSetId?: string | null }) => boolean;
};

const CardPreviewContext = createContext<CardPreviewContextValue>({
    isOpen: false,
    noteId: null,
    flashcardSetId: null,
    openCardPreview: () => null,
    closeCardPreview: () => null,
    isCurrentlyInView: () => false,
});

export const CardPreviewContextProvider = ({ children }: { children: ReactNode }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [currNoteId, setCurrNoteId] = useState<string | null>(null);
    const [currFlashcardSetId, setCurrFlashcardSetId] = useState<string | null>(null);

    const openCardPreview = ({ noteId, flashcardSetId }: WithNoteId | WithFlashcardSetId) => {
        if (noteId) {
            setCurrNoteId(noteId);
            setCurrFlashcardSetId(null);
        }
        if (flashcardSetId) {
            setCurrFlashcardSetId(flashcardSetId);
            setCurrNoteId(null);
        }
        setIsOpen(true);
    };

    const closeCardPreview = () => {
        setIsOpen(false);
        // let the popup close first before resetting state
        setTimeout(() => {
            setCurrNoteId(null);
            setCurrFlashcardSetId(null);
        }, 30);
    };

    const isCurrentlyInView = ({ noteId, flashcardSetId }: { noteId?: string; flashcardSetId?: string }): boolean => {
        if (!isOpen) {
            return false;
        }

        if (typeof noteId === "string") {
            return currNoteId === noteId;
        }
        if (typeof flashcardSetId === "string") {
            return currFlashcardSetId === flashcardSetId;
        }

        return false;
    };

    return (
        <CardPreviewContext.Provider
            value={{
                isOpen,
                openCardPreview,
                closeCardPreview,
                noteId: currNoteId,
                flashcardSetId: currFlashcardSetId,
                isCurrentlyInView,
            }}>
            {children}
            <LazyLoaded load={isOpen}>
                <ContentPreviewerPopup />
            </LazyLoaded>
        </CardPreviewContext.Provider>
    );
};

export const useCardPreviewContextSelector = <T,>(selector: (state: CardPreviewContextValue) => T) =>
    useContextSelector(CardPreviewContext, selector);

import { iconSizes } from "@/utils/iconProps";

const StreakDailyActivityIcon = ({
    size = iconSizes.LG,
    ...props
}: { size?: number } & React.SVGProps<SVGSVGElement>) => {
    return (
        <svg width={size} height={size} viewBox="0 0 30 29" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <circle cx="15.0009" cy="14.6868" r="14.165" fill="#FFEEE2" />
            <path
                d="M21.302 10.4898L12.6457 19.1462L8.71094 15.2115"
                stroke="#FF7C1D"
                strokeWidth={3}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default StreakDailyActivityIcon;

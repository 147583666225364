"use client";

import { refreshSession } from "@knowt/syncing/hooks/user/auth";
import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";
import { now, TIME_SECONDS } from "@knowt/syncing/utils/dateTimeUtils";
import { useEffect, useState } from "react";
import DelayedComponent from "@knowt/syncing/components/DelayedComponent";

export const DelayedRefreshChromeExtensionSession = () => {
    const { userId } = useCurrentUser({ waitForFallbackData: true });
    const [isExtensionInstalled, setIsExtensionInstalled] = useState<boolean | null>(null);
    const [lastSentAuth, setLastSentAuth] = useState(0);

    useEffect(() => {
        // call refresh session on every page mount, just in case the idToken is expired
        refreshSession().then(() => setLastSentAuth(now()));
    }, []);

    useEffect(() => {
        if (!window.chrome) return;
        if (!userId) return;

        const chromeExtensionId = process.env.NEXT_PUBLIC_CHROME_EXTENSION_ID;

        if (isExtensionInstalled === null) {
            if (!chromeExtensionId) {
                return;
            }

            chrome.runtime?.sendMessage(chromeExtensionId, { installCheck: true }, reply => {
                setIsExtensionInstalled(reply?.confirmation === "knowt-chrome-bananas");
            });
        }

        if (isExtensionInstalled && now() - lastSentAuth > TIME_SECONDS.HOUR) {
            refreshSession().then(() => setLastSentAuth(now()));
        }
    }, [userId, isExtensionInstalled, lastSentAuth]);

    return null;
};

export const RefreshChromeExtensionSession = () => {
    return (
        <DelayedComponent delay={500}>
            <DelayedRefreshChromeExtensionSession />
        </DelayedComponent>
    );
};

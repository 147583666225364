"use client";
import LazyLoaded from "@/components/LazyLoaded";
import { Assignment, FlashcardSet, Folder, ItemType, Media, Note } from "@knowt/syncing/graphql/schema";
import { createContext, useContextSelector } from "@knowt/syncing/utils/use-context-selector";
import dynamic from "next/dynamic";
import React, { useCallback, useMemo, useState } from "react";

const ContentItemsSharePopup = dynamic(() => import("../components/ContentItemsSharePopup"));

type PopupValue = {
    itemType?: ItemType;
    showClassCreation?: boolean;
    isNew?: boolean;
    onSaved?: ({
        classId,
        sections,
        isPublic,
        isRelatedMediaPublic,
        password,
    }: {
        classId?: string | null;
        sections?: string[] | null;
        isPublic?: boolean | null;
        isRelatedMediaPublic?: boolean | null;
        password?: string | null;
    }) => void;
    medias?: Media[];
    notes?: Note[];
    flashcardSets?: FlashcardSet[];
    folders?: Folder[];
    assignments?: Assignment[];
    onClose?: () => void;
};

type ContentItemsSharingPopupContextValue = {
    openContentItemsSharingPopup: ({
        itemType,
        showClassCreation,
        isNew,
        medias,
        notes,
        flashcardSets,
        folders,
        assignments,
        onClose,
    }: PopupValue) => void;
};

const ContentItemsSharingPopupContext = createContext<ContentItemsSharingPopupContextValue | null>(null);

export const ContentItemsSharingPopupContextProvider = ({ children }: { children: React.ReactNode }) => {
    const [isContentItemsSharingPopupOpen, setIsContentItemsSharingPopupOpen] = useState(false);

    const [value, setValue] = useState<PopupValue | null>(null);

    const resetAllState = () => {
        setIsContentItemsSharingPopupOpen(false);
        setValue(null);
        value?.onClose?.();
    };

    const openContentItemsSharingPopup = useCallback(
        ({
            itemType,
            onSaved,
            showClassCreation,
            medias,
            notes,
            flashcardSets,
            folders,
            assignments,
            isNew,
        }: PopupValue) => {
            setValue({
                itemType,
                onSaved,
                showClassCreation,
                medias,
                notes,
                flashcardSets,
                folders,
                assignments,
                isNew,
            });
            setIsContentItemsSharingPopupOpen(true);
        },
        []
    );

    return (
        <ContentItemsSharingPopupContext.Provider
            value={useMemo(() => ({ openContentItemsSharingPopup }), [openContentItemsSharingPopup])}>
            {children}
            <LazyLoaded load={isContentItemsSharingPopupOpen && !!value}>
                <ContentItemsSharePopup
                    isOpen={isContentItemsSharingPopupOpen}
                    onClose={resetAllState}
                    showClassCreation={value?.showClassCreation}
                    // we know that value is not null here because of the LazyLoad
                    itemType={value?.itemType!}
                    onSaved={value?.onSaved}
                    medias={value?.medias}
                    notes={value?.notes}
                    flashcardSets={value?.flashcardSets}
                    folders={value?.folders}
                    assignments={value?.assignments}
                    isNew={value?.isNew}
                />
            </LazyLoaded>
        </ContentItemsSharingPopupContext.Provider>
    );
};

export const useContentItemsSharingPopupContextSelector = <T,>(
    selector: (state: ContentItemsSharingPopupContextValue) => T
) => useContextSelector(ContentItemsSharingPopupContext, selector);

"use client";

import { useProcessAsyncActions } from "@/hooks/gamification/monitoring/useProcessAsyncActions";

const ProcessAsyncActions = () => {
    useProcessAsyncActions();
    return null;
};

export default ProcessAsyncActions;

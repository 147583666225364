"use client";

import { usePathname, useSearchParams } from "next/navigation";
import { useEffect } from "react";

const MaybeScrollToAnchor = () => {
    const pathname = usePathname();
    const searchParams = useSearchParams();

    useEffect(() => {
        if (typeof window === "undefined") return;

        const fullUrl = window.location.href;

        // get the anchor from the url
        const anchor = fullUrl.split("#")[1];

        if (anchor && typeof document !== "undefined") {
            const anchorElement = document.getElementById(anchor);
            if (anchorElement) {
                anchorElement.scrollIntoView({ behavior: "smooth", block: "center" });
            }
        }
    }, [pathname, searchParams]);

    return null;
};

export default MaybeScrollToAnchor;

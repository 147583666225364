import { iconSizes } from "@/utils/iconProps";

const FreezeStreakIcon = ({
    showPauseLine,
    size = iconSizes.LG,
    ...props
}: { size?: number; showPauseLine?: boolean } & React.SVGProps<SVGSVGElement>) => {
    return (
        <svg width={size} height={size} viewBox="0 0 44 45" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M20.0826 2.53354C21.0701 1.31369 22.9305 1.31369 23.918 2.53354L28.8181 8.58667C29.2018 9.06079 29.7478 9.37592 30.3502 9.47125L38.0425 10.6883C39.5926 10.9336 40.5228 12.5447 39.9602 14.0098L37.168 21.28C36.9493 21.8494 36.9493 22.4797 37.168 23.0492L39.9602 30.3193C40.5228 31.7845 39.5926 33.3956 38.0425 33.6409L30.3502 34.8579C29.7478 34.9532 29.2018 35.2683 28.8181 35.7424L23.918 41.7956C22.9305 43.0155 21.0701 43.0155 20.0826 41.7956L15.1825 35.7424C14.7987 35.2683 14.2529 34.9532 13.6504 34.8579L5.95814 33.6409C4.40799 33.3956 3.47777 31.7845 4.04044 30.3193L6.83257 23.0492C7.05127 22.4797 7.05125 21.8494 6.83257 21.28L4.04044 14.0098C3.47777 12.5447 4.40799 10.9336 5.95814 10.6883L13.6504 9.47125C14.2529 9.37592 14.7987 9.06079 15.1825 8.58667L20.0826 2.53354Z"
                fill="#0BC0FA"
            />
            <path
                d="M20.7168 6.47272C21.375 5.65949 22.6154 5.65949 23.2738 6.47272L27.418 11.5923C27.674 11.9084 28.0379 12.1185 28.4396 12.182L34.9455 13.2114C35.979 13.3749 36.599 14.449 36.2239 15.4258L33.8624 21.5747C33.7166 21.9543 33.7166 22.3745 33.8624 22.7542L36.2239 28.9032C36.599 29.8798 35.979 30.954 34.9455 31.1175L28.4396 32.1469C28.0379 32.2104 27.674 32.4205 27.418 32.7365L23.2736 37.8561C22.6154 38.6694 21.375 38.6694 20.7168 37.8561L16.5724 32.7365C16.3165 32.4205 15.9526 32.2104 15.5509 32.1469L9.04502 31.1175C8.01157 30.954 7.39143 29.8798 7.76655 28.9032L10.1281 22.7542C10.2739 22.3745 10.2739 21.9543 10.1281 21.5747L7.76655 15.4258C7.39143 14.449 8.01157 13.3749 9.04502 13.2114L15.5509 12.182C15.9526 12.1185 16.3165 11.9084 16.5724 11.5923L20.7168 6.47272Z"
                fill="#74E1FF"
            />
            {showPauseLine && (
                <>
                    <path
                        d="M26.5217 17.5388C26.5217 17.0277 26.1763 16.6133 25.7504 16.6133H24.2088C23.7829 16.6133 23.4375 17.0277 23.4375 17.5388V26.7906C23.4375 27.3018 23.7829 27.7161 24.2088 27.7161H25.7504C26.1763 27.7161 26.5217 27.3018 26.5217 26.7906V17.5388Z"
                        fill="white"
                    />
                    <path
                        d="M20.5606 17.5388C20.5606 17.0277 20.2154 16.6133 19.7893 16.6133H18.2478C17.8219 16.6133 17.4766 17.0277 17.4766 17.5388V26.7906C17.4766 27.3018 17.8219 27.7161 18.2478 27.7161H19.7893C20.2154 27.7161 20.5606 27.3018 20.5606 26.7906V17.5388Z"
                        fill="white"
                    />
                </>
            )}
        </svg>
    );
};

export default FreezeStreakIcon;

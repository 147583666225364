"use client";

import Intercom, { update } from "@intercom/messenger-js-sdk";
import { PlatformEnum } from "@knowt/syncing/graphql/schema";
import { callGetIntercomUserHash } from "@knowt/syncing/hooks/user/graphqlUtils";
import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";
import { useEffect, useRef } from "react";

const ConfigureIntercom = () => {
    const { user } = useCurrentUser({ waitForFallbackData: true });

    const initialzed = useRef(false);
    useEffect(() => {
        if (!user || initialzed.current) return;
        initialzed.current = true;

        const asyncEffect = async () => {
            const userHash = await callGetIntercomUserHash(PlatformEnum.WEB);

            Intercom({
                app_id: "f0cwri6v",
                user_id: user.ID ?? undefined,
                user_hash: userHash ?? undefined,
                name: user.Name ?? undefined,
                email: user.Email,
                created_at: Number(user.created),
                hide_default_launcher: true,
            });

            update({
                customAttributes: {
                    accountType: user.accountType,
                    birthday: user.birthday,
                    timeZone: user.timeZone,
                },
            });
        };

        asyncEffect();
    }, [user]);

    return null;
};

export default ConfigureIntercom;

"use client";

import React, { useEffect, useState } from "react";

type DelayedComponentProps = React.PropsWithChildren<{
    delay: number;
}>;

const DelayedComponent = ({ delay, children }: DelayedComponentProps) => {
    const [ready, setReady] = useState(false);

    useEffect(() => {
        const timeout = setTimeout(() => setReady(true), delay);
        return () => clearTimeout(timeout);
    }, [delay]);

    return (ready ? children : null) as React.ReactElement;
};

export default DelayedComponent;

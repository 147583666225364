"use client";

import { useCurrentUser } from "@/hooks/user/useCurrentUser";
import { safeIntlTZ } from "@/utils/timezone";
import { useEffect, useRef, useState } from "react";
import { updateUserInfo } from "@/hooks/user/utils";
import LazyLoaded from "@/components/LazyLoaded";
import ResponsiveDialog from "@/features/ResponsiveDialog/ResponsiveDialog";
import { borderRadius } from "@/utils/borderRadius";
import { FlexColumn, FlexColumnAlignJustifyCenter, FlexRowAlignCenter } from "@/components/Flex";
import CircularRectTextButton from "@/components/CircularButton/styled/CircularRectTextButton";
import { themeColors } from "@/utils/themeColors";
import { useLocalStorageSWR } from "@knowt/syncing/hooks/swr/localStorage";
import { TIMEZONE_CHANGE_INTERVAL, getFormattedTimeFromNow, getOffset, now } from "@knowt/syncing/utils/dateTimeUtils";
import { ArrowRight, Globe, GlobeLock, TriangleAlert } from "lucide-react";
import { ALL_VALID_TIMEZONES } from "@knowt/syncing/utils/timeZones";

const TimezoneCard = ({ timezone, isEligible = true }: { timezone: string; isEligible?: boolean }) => {
    const [country, city, ..._rest] = timezone?.split("/") ?? [];

    const Icon = isEligible ? Globe : GlobeLock;

    if (!country) return null;

    return (
        <FlexColumnAlignJustifyCenter
            style={{
                border: `1px solid ${themeColors.neutral1}`,
                borderRadius: borderRadius.card,
                gap: "0.8rem",
                padding: "1.2rem 3rem",
            }}>
            <Icon size={30} />
            <p className="body2">
                {country} - {city?.split("_")?.join(" ")}
            </p>
            <p className="body2">(GMT {getOffset(timezone)})</p>
        </FlexColumnAlignJustifyCenter>
    );
};

const EligibleTimezoneSwap = ({ oldTimezone, newTimezone }: { oldTimezone: string; newTimezone: string }) => {
    return (
        <FlexRowAlignCenter style={{ gap: "1.2rem" }}>
            <TimezoneCard timezone={oldTimezone} />
            <ArrowRight size={24} />
            <TimezoneCard timezone={newTimezone} />
        </FlexRowAlignCenter>
    );
};

const IneligibleTimezoneSwap = ({ oldTimezone, newTimezone }: { oldTimezone: string; newTimezone: string }) => {
    return (
        <FlexRowAlignCenter style={{ gap: "1.2rem" }}>
            <TimezoneCard timezone={oldTimezone} />
            <TriangleAlert size={24} />
            <TimezoneCard timezone={newTimezone} />
        </FlexRowAlignCenter>
    );
};

const ChangeTimezonePopup = ({
    isOpen,
    onClose,
    userTimezone,
    nextChange,
    currentTZ,
    isEligible,
}: {
    isOpen: boolean;
    onClose: () => void;
    userTimezone: string;
    nextChange: number;
    currentTZ: string | null;
    isEligible: boolean;
}) => {
    const title = isEligible
        ? "Do you want to change your time zone?"
        : "Oops! You’ve recently updated your time zone.";

    const description = isEligible
        ? `For security reasons you can only change timezones every 3 days. If you are using a VPN, please decline this alert.`
        : `We limit time zone changes to once every 3 days for security reasons. You can update your timezone in ${getFormattedTimeFromNow(
              nextChange,
              "hour"
          )}.`;

    const TopComponent = isEligible ? EligibleTimezoneSwap : IneligibleTimezoneSwap;

    return (
        <ResponsiveDialog
            isOpen={isOpen}
            onClose={onClose}
            DialogStyle={{
                borderRadius: borderRadius.card,
                padding: "3.2rem",
                width: "70rem",
                height: "auto",
                gap: "1.5rem",
            }}
            SlideMenuStyle={{
                padding: "3.2rem",
            }}>
            <FlexColumnAlignJustifyCenter
                style={{
                    gap: "2.4rem",
                    padding: "3.2rem",
                    paddingBottom: "0",
                }}>
                <TopComponent oldTimezone={userTimezone} newTimezone={currentTZ} />
                <FlexColumn style={{ gap: "0.8rem", width: "100%", textAlign: "center" }}>
                    <h4 className="heading4">{title}</h4>
                    <p className="body2">{description}</p>
                </FlexColumn>
                <FlexColumnAlignJustifyCenter
                    style={{
                        gap: "1.8rem",
                        width: "100%",
                    }}>
                    <CircularRectTextButton
                        onClick={() => {
                            if (isEligible) {
                                updateUserInfo({ timeZone: currentTZ });
                            }
                            onClose();
                        }}
                        className="bodyBold2"
                        style={{
                            padding: "1.2rem 2.4rem",
                            backgroundColor: themeColors.primary,
                            color: themeColors.primaryDark,
                            height: "5rem",
                        }}>
                        Continue
                    </CircularRectTextButton>
                    {isEligible && (
                        <CircularRectTextButton
                            onClick={onClose}
                            className="bodyBold2"
                            style={{
                                padding: "1.2rem 2.4rem",
                                backgroundColor: "transparent",
                                height: "5rem",
                            }}>
                            Decline
                        </CircularRectTextButton>
                    )}
                </FlexColumnAlignJustifyCenter>
            </FlexColumnAlignJustifyCenter>
        </ResponsiveDialog>
    );
};

const SyncUserTimezone = () => {
    const [isOpen, setIsOpen] = useState(false);

    const { user, tz } = useCurrentUser({ waitForFallbackData: true });
    const { data, set } = useLocalStorageSWR<string | null>(`${user?.ID}-timezone-alert`);
    const isEligible = user?.lastTimeZoneChange ? now() - user?.lastTimeZoneChange > TIMEZONE_CHANGE_INTERVAL : true;

    const currentTZ = safeIntlTZ();

    const tzSyncedRef = useRef(false);

    useEffect(() => {
        if (!user || !currentTZ || data === undefined) return;
        if (tzSyncedRef.current) return;

        tzSyncedRef.current = true;

        const [alertTZ, wasEligible] = data?.split(":::") || [null, false];

        if (user.timeZone && getOffset(user.timeZone) === getOffset(currentTZ)) {
            return;
        }

        if (!ALL_VALID_TIMEZONES.includes(currentTZ.toLowerCase())) {
            // set to default of New York
            updateUserInfo({ timeZone: "America/New_York" });
            return;
        }
        if (!user.timeZone || !ALL_VALID_TIMEZONES.includes(user.timeZone.toLowerCase())) {
            updateUserInfo({ timeZone: currentTZ });
            return;
        }

        // Only open if:
        // the timezones do not match
        // AND

        // the user has not seen the alert for the current timezone
        // OR
        // the user wasn't eligible, but now they are.
        if (
            user.timeZone.toLowerCase() !== currentTZ.toLowerCase() &&
            (alertTZ !== currentTZ.toLowerCase() || (!wasEligible && isEligible))
        ) {
            setIsOpen(true);
        }
    }, [user, currentTZ, data, isEligible, set]);

    return (
        <LazyLoaded load={isOpen}>
            <ChangeTimezonePopup
                isOpen={isOpen}
                onClose={() => {
                    if (currentTZ) {
                        set(`${currentTZ.toLowerCase()}:::${isEligible}`);
                    }
                    setIsOpen(false);
                }}
                userTimezone={tz}
                nextChange={user?.lastTimeZoneChange ? user.lastTimeZoneChange + TIMEZONE_CHANGE_INTERVAL : now()}
                currentTZ={currentTZ}
                isEligible={isEligible}
            />
        </LazyLoaded>
    );
};

export default SyncUserTimezone;

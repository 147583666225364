"use client";

import CircularOutlineIcon from "@/components/CircularButton/styled/CircularOutlineIcon";
import { FlexColumnAlignJustifyCenter, FlexRowAlignCenter } from "@/components/Flex";
import FreezeStreakIcon from "@/components/icons/FreezeStreakIcon";
import StreakIcon from "@/components/icons/StreakIcon";
import StreakPauseIcon from "@/components/icons/StreakPauseIcon";
import StreakRevivalIcon from "@/components/icons/StreakRevivalIcon";
import {
    isToday as _isToday,
    isYesterday as _isYesterday,
} from "@/hooks/gamification/monitoring/streakMonitoring/utils";
import { borderRadius } from "@/utils/borderRadius";
import { themeColors } from "@/utils/themeColors";
import { ActionEnum, DailyActivity } from "@knowt/syncing/graphql/schema";
import { useDailyActivities } from "@knowt/syncing/hooks/gamification/activities/useDailyActivity";
import { ExtraActionEnum, STREAK_ACTION_COLORS } from "@knowt/syncing/hooks/gamification/constants";
import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";
import { isLastDayOfMonth, thisDay, today } from "@knowt/syncing/utils/dateTimeUtils";
import { SxProps } from "@mui/material/styles";
import { DateCalendar, PickersDay, PickersDayProps } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { ChevronLeft, ChevronRight } from "lucide-react";
import React, { useMemo } from "react";

export const streakCalendarLineColors = {
    [ActionEnum.STREAK_FREEZE]: themeColors.freezeLight,
    [ActionEnum.LONG_PAUSE]: themeColors.neutral1,
    [ActionEnum.STREAK_REVIVAL]: themeColors.revivalLight,
    [ExtraActionEnum.STREAK]: themeColors.streakLight,
};

const CalendarIcon = ({
    dayAction,
    yesterdayAction,
    tomorrowAction,
}: {
    dayAction: ActionEnum | null;
    yesterdayAction: ActionEnum | null;
    tomorrowAction: ActionEnum | null;
}) => {
    // if its in the middle of a row of actions, we only need images at the start and end
    if (dayAction === yesterdayAction && dayAction === tomorrowAction) {
        return "";
    }

    const style: React.CSSProperties = {
        position: "absolute",
        zIndex: 0,
    };

    switch (dayAction) {
        case ActionEnum.STREAK_FREEZE:
            return <FreezeStreakIcon style={style} />;
        case ActionEnum.LONG_PAUSE: {
            return <StreakPauseIcon style={style} />;
        }
        case ActionEnum.STREAK_REVIVAL:
            return (
                <StreakRevivalIcon
                    style={{
                        ...style,
                        marginTop: "0.4rem",
                    }}
                />
            );

        default:
            return null;
    }
};

const isStartOfStreak = (day, dates: DailyActivity[], tz: string | undefined) => {
    const todayStreak = !dates.find(date => date.activityDate === day)?.streakAction;

    if (todayStreak) {
        return false;
    }

    const previousDay = thisDay(day, tz).subtract(1, "day").format("YYYY-MM-DD");

    return !dates
        .filter(date => !!date.streakAction)
        .map(date => date.activityDate)
        .includes(previousDay);
};

const isEndOfStreak = (day, dates: DailyActivity[], tz: string | undefined) => {
    const todayStreak = !dates.find(date => date.activityDate === day)?.streakAction;

    if (todayStreak) {
        return false;
    }

    if (_isYesterday(day, tz)) {
        return false;
    }

    const nextDay = dayjs(day).add(1, "day").format("YYYY-MM-DD");

    return !dates
        .filter(date => !!date.streakAction)
        .map(date => date.activityDate)
        .includes(nextDay);
};

const CustomHeader = ({ currentMonth, onMonthChange, streakCount, isTodayStreakDone, isHeaderControlOnRight }) => {
    const handlePrevMonth = () => {
        onMonthChange(dayjs(currentMonth).subtract(1, "month"), "left");
    };

    const handleNextMonth = () => {
        onMonthChange(dayjs(currentMonth).add(1, "month"), "right");
    };

    return (
        <FlexRowAlignCenter style={{ display: "flex", justifyContent: "space-between", marginBottom: "1rem" }}>
            {!isHeaderControlOnRight && (
                <CircularOutlineIcon
                    size={24}
                    strokeWidth={2.4}
                    Icon={ChevronLeft}
                    onClick={handlePrevMonth}
                    hoverColor={themeColors.neutralBlack}
                    borderColor={themeColors.neutral1}
                    sx={{
                        "&:hover": {
                            backgroundColor: themeColors.neutral1,
                        },
                    }}
                />
            )}
            <FlexRowAlignCenter>
                <span id="month-label" className="bodyBold2" style={{ margin: "0 1rem" }}>
                    {dayjs(currentMonth).format("MMMM YYYY")}
                </span>
                {!isHeaderControlOnRight && (
                    <>
                        <StreakIcon isComplete={isTodayStreakDone} />
                        <span
                            className="bodyBold2"
                            style={{
                                margin: "0 0.6rem",
                                color: isTodayStreakDone
                                    ? STREAK_ACTION_COLORS[ExtraActionEnum.STREAK]
                                    : themeColors.neutral3,
                            }}>
                            {streakCount}
                        </span>
                    </>
                )}
            </FlexRowAlignCenter>
            <FlexRowAlignCenter>
                {isHeaderControlOnRight && (
                    <CircularOutlineIcon
                        size={24}
                        strokeWidth={2.4}
                        Icon={ChevronLeft}
                        onClick={handlePrevMonth}
                        hoverColor={themeColors.neutralBlack}
                        borderColor={isHeaderControlOnRight ? "transparent" : themeColors.neutral1}
                        sx={{
                            "&:hover": {
                                backgroundColor: themeColors.neutral1,
                            },
                        }}
                    />
                )}
                <CircularOutlineIcon
                    size={24}
                    strokeWidth={2.4}
                    Icon={ChevronRight}
                    onClick={handleNextMonth}
                    hoverColor={themeColors.neutralBlack}
                    borderColor={isHeaderControlOnRight ? "transparent" : themeColors.neutral1}
                    sx={{
                        "&:hover": {
                            backgroundColor: themeColors.neutral1,
                        },
                    }}
                />
            </FlexRowAlignCenter>
        </FlexRowAlignCenter>
    );
};

const StreaksCalendar = ({
    isHeaderControlOnRight = false,
    styles,
    setMonth,
}: {
    isHeaderControlOnRight?: boolean;
    styles?: {
        calendar?: SxProps;
        header?: React.CSSProperties;
    };
    setMonth: (month: string) => void;
}) => {
    const { user, tz } = useCurrentUser();
    const { dailyActivities } = useDailyActivities({ numberOfDays: 1000 });

    const activityDates = useMemo(() => {
        return (
            dailyActivities?.map(activity => ({
                ...activity,
                activityDate: dayjs(activity.activityDate).format("YYYY-MM-DD"),
            })) ?? []
        );
    }, [dailyActivities]);

    const isTodayStreakDone = !!activityDates.find(activity => activity.activityDate === today(tz).format("YYYY-MM-DD"))
        ?.streakAction;

    const gradient = (color1: string, color2: string) => `linear-gradient(to right, ${color1} 50%, ${color2} 50%)`;

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar
                readOnly
                reduceAnimations
                sx={{
                    width: "100%",
                    maxHeight: "unset",
                    height: "100%",
                    borderRadius: borderRadius.card,
                    backgroundColor: themeColors.neutralWhite,
                    "& .MuiDayCalendar-header": {
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                    },
                    "& .MuiDayCalendar-weekDayLabel": {
                        fontSize: "1.4rem",
                        fontWeight: "800",
                        width: "100%",
                        color: themeColors.neutralBlack,
                        fontFamily: "var(--knowt-font-name)",
                    },
                    "& .MuiPickersSlideTransition-root": {
                        display: "flex",
                        alignItems: "stretch",
                        flexDirection: "column",
                        justifyContent: "space-between",
                    },
                    "& .MuiDayCalendar-weekContainer": {
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-between",
                    },
                    "& .MuiPickersDay-dayOutsideMonth": {
                        color: themeColors.neutral1,
                    },
                    "& .mui-flbe84-MuiDayCalendar-weekContainer": {
                        margin: "0.4rem 0",
                    },
                    "& .MuiPickersDay-root:not(.Mui-selected)": {
                        border: "none",
                        backgroundColor: "transparent",
                    },
                    "& .MuiPickersDay-root:hover": {
                        backgroundColor: "transparent",
                    },
                    ...styles?.calendar,
                }}
                slots={{
                    calendarHeader: props => (
                        <CustomHeader
                            {...props}
                            onMonthChange={(date: dayjs.Dayjs, slideDirection: "left" | "right") => {
                                setMonth(date.format("MM"));
                                props.onMonthChange(date, slideDirection);
                            }}
                            streakCount={user?.streak ?? 0}
                            isTodayStreakDone={isTodayStreakDone}
                            isHeaderControlOnRight={isHeaderControlOnRight}
                        />
                    ),
                    day: (calendar: PickersDayProps<Date>) => {
                        const { day } = calendar;

                        const formattedDay = dayjs(day).format("YYYY-MM-DD");
                        const formattedYesterday = dayjs(day).subtract(1, "day").format("YYYY-MM-DD");
                        const formattedTomorrow = dayjs(day).add(1, "day").format("YYYY-MM-DD");

                        const yesterdayAction =
                            activityDates.find(activity => activity.activityDate === formattedYesterday)
                                ?.streakAction ?? null;

                        const dayAction =
                            activityDates.find(activity => activity.activityDate === formattedDay)?.streakAction ??
                            null;

                        const tomorrowAction =
                            activityDates.find(activity => activity.activityDate === formattedTomorrow)?.streakAction ??
                            null;

                        const isToday = _isToday(formattedDay, tz);
                        const isYesterday = _isYesterday(formattedDay, tz);
                        const isStreakDay = !!dayAction;

                        const streakActionWithImage =
                            dayAction === ActionEnum.STREAK_FREEZE ||
                            dayAction === ActionEnum.LONG_PAUSE ||
                            dayAction === ActionEnum.STREAK_REVIVAL;

                        const isStartDay = isStartOfStreak(formattedDay, activityDates, tz);
                        const isEndDay = isEndOfStreak(formattedDay, activityDates, tz);

                        const isStartOfMonth = day.$D === 1;
                        const isEndOfMonth = isLastDayOfMonth(day.$d);

                        const isStartOfWeek = day.$W === 0;
                        const isEndOfWeek = day.$W === 6;

                        const isEndsOfStreak = isStartDay || isEndDay;

                        const getBorderRadius = () => {
                            // we have to do this in case the first/last day of the month is both the start and end of a week

                            const leftBorderRadius = isStartOfMonth || isStartOfWeek ? [20, 0, 0, 20] : [0, 0, 0, 0];
                            const rightBorderRadius = isEndOfMonth || isEndOfWeek ? [0, 20, 20, 0] : [0, 0, 0, 0];

                            const borderRadius = `${leftBorderRadius[0]}px ${rightBorderRadius[1]}px ${rightBorderRadius[2]}px ${leftBorderRadius[3]}px`;

                            return borderRadius;
                        };

                        const getStreakBackgroundLine = () => {
                            if (isStartDay && isEndDay) {
                                return "transparent";
                            }

                            if (isEndDay) {
                                return gradient(
                                    streakCalendarLineColors[dayAction || ""] ??
                                        streakCalendarLineColors[ExtraActionEnum.STREAK],
                                    "transparent"
                                );
                            }

                            if (isStartDay) {
                                return gradient(
                                    "transparent",
                                    streakCalendarLineColors[dayAction || ""] ??
                                        streakCalendarLineColors[ExtraActionEnum.STREAK]
                                );
                            }

                            if (!isStreakDay) {
                                if (isToday && !!yesterdayAction) {
                                    return gradient(
                                        streakCalendarLineColors[yesterdayAction] ??
                                            streakCalendarLineColors[ExtraActionEnum.STREAK],
                                        "transparent"
                                    );
                                }

                                return "transparent";
                            }

                            let yesterdayGradient = streakCalendarLineColors[ExtraActionEnum.STREAK];
                            let tomorrowGradient = streakCalendarLineColors[ExtraActionEnum.STREAK];

                            if (dayAction === yesterdayAction) {
                                yesterdayGradient = streakCalendarLineColors[yesterdayAction] ?? yesterdayGradient;
                            }

                            if (dayAction === tomorrowAction) {
                                tomorrowGradient = streakCalendarLineColors[tomorrowAction] ?? tomorrowGradient;
                            }

                            if (isYesterday && !tomorrowAction) {
                                // make the right side of the line match today's color
                                tomorrowGradient =
                                    streakCalendarLineColors[dayAction] ??
                                    streakCalendarLineColors[ExtraActionEnum.STREAK];
                            }

                            return gradient(yesterdayGradient, tomorrowGradient);
                        };

                        const getTextColor = () => {
                            if (streakActionWithImage) {
                                if (dayAction === yesterdayAction && dayAction === tomorrowAction) {
                                    return themeColors.neutralBlack;
                                }

                                return themeColors.pureWhite;
                            }

                            // if it is a streak day (but not start or end)
                            if (!isStreakDay) {
                                return themeColors.neutralBlack;
                            }

                            if ((isEndDay && !isYesterday) || isToday || isStartDay) {
                                return themeColors.pureWhite;
                            }

                            return themeColors.streakDark;
                        };

                        if (calendar.outsideCurrentMonth) {
                            // placeholder so the flexbox doesn't break
                            return (
                                <PickersDay
                                    {...calendar}
                                    sx={{
                                        width: "100%",
                                        fontWeight: 700,
                                        lineHeight: 1.6,
                                        fontSize: "1.2rem",
                                        fontFamily: "var(--knowt-font-name)",
                                        color: themeColors.neutral1,
                                        margin: 0,
                                        visibility: "hidden",
                                    }}
                                />
                            );
                        }

                        return (
                            <PickersDay
                                {...calendar}
                                sx={{
                                    width: "100%",
                                    fontWeight: 700,
                                    lineHeight: 1.6,
                                    fontSize: "1.2rem",
                                    fontFamily: "var(--knowt-font-name)",
                                    color: getTextColor(),
                                    margin: 0,
                                }}>
                                <CalendarIcon
                                    dayAction={dayAction}
                                    yesterdayAction={yesterdayAction}
                                    tomorrowAction={tomorrowAction}
                                />
                                <FlexColumnAlignJustifyCenter
                                    style={{
                                        textAlign: "center",
                                        background: getStreakBackgroundLine(),
                                        padding: "0rem",
                                        borderRadius: getBorderRadius(),
                                        flexGrow: isStreakDay || isToday ? 1 : 0,
                                        marginRight: isStartDay ? "0" : "unset",
                                    }}>
                                    <FlexColumnAlignJustifyCenter
                                        style={{
                                            backgroundColor:
                                                isStreakDay && isEndsOfStreak && !streakActionWithImage
                                                    ? STREAK_ACTION_COLORS[ExtraActionEnum.STREAK]
                                                    : isToday && !isTodayStreakDone
                                                      ? themeColors.neutralWhite
                                                      : "transparent",
                                            border: isToday
                                                ? `2px ${isStreakDay ? "solid" : "dashed"} ${
                                                      isStreakDay
                                                          ? themeColors.neutralBlack
                                                          : STREAK_ACTION_COLORS[ExtraActionEnum.STREAK]
                                                  }`
                                                : "none",
                                            borderRadius: "50%",
                                            width: "3.2rem",
                                            height: "3.2rem",
                                        }}>
                                        <span style={{ zIndex: 1 }}>{dayjs(day).format("D")}</span>
                                    </FlexColumnAlignJustifyCenter>
                                </FlexColumnAlignJustifyCenter>
                            </PickersDay>
                        );
                    },
                }}
                timezone={tz}
                showDaysOutsideCurrentMonth
            />
        </LocalizationProvider>
    );
};

export default StreaksCalendar;

"use client";

import LazyLoaded from "@/components/LazyLoaded";
import { Dispatch, SetStateAction, useCallback, useState } from "react";
import { createContext, useContextSelector } from "@knowt/syncing/utils/use-context-selector";
import dynamic from "next/dynamic";
import { FeedbackEvents, FeedbackObject, feedbackPopupContent } from "@/components/Feedback/feedbackPopupContent";
import toast from "react-hot-toast";

const FeedbackFlowPopup = dynamic(() => import("@/components/Feedback/FeedbackFlowPopup"));

type FeedbackSubmitHandlerProps = {
    reason: string;
    category: string;
    followUp: string;
    rating: number;
};

type FeedbackSubmission = {
    [key: string]: number;
};

export type FeedbackSubmitHandler = ({ rating, reason, category, followUp }: FeedbackSubmitHandlerProps) => void;

type FeedbackFlowContextValue = {
    openFeedbackPopup: ({
        feedbackFor,
        onSubmitHandler,
        rating,
    }: {
        feedbackFor: FeedbackEvents;
        onSubmitHandler?: FeedbackSubmitHandler;
        rating: number;
    }) => void;
    closeFeedbackPopup: () => void;
    isFeedbackPopupOpen: boolean;
    handleOptionClick: (option: string) => void;
    showOtherInput: boolean;
    userFeedbackValue: string;
    setUserFeedbackValue: (value: string) => void;
    currentFeedbackPopupContent: FeedbackObject | null;
    submitFeedback: () => void;
    handleGoBack: () => void;
    feedbackPopupLevel: number;
    submissions: FeedbackSubmission;
    setSubmissions: Dispatch<SetStateAction<FeedbackSubmission>>;
    resetFeedbackSubmissions: () => void;
};

const FeedbackFlowContext = createContext<FeedbackFlowContextValue | null>(null);

export const FeedbackFlowContextProvider = ({ children }) => {
    const [isFeedbackPopupOpen, setIsFeedbackPopupOpen] = useState(false);
    const [currentFeedbackEvent, setCurrentFeedbackEvent] = useState<FeedbackEvents | "">("");
    const [feedbackPopupLevel, setFeedbackPopupLevel] = useState(0);

    const [rating, setRating] = useState<number | null>(null);

    const [onSubmitHandler, setOnSubmitHandler] = useState<FeedbackSubmitHandler | undefined>(undefined);

    const [submissions, setSubmissions] = useState<FeedbackSubmission>({});

    // biome-ignore lint/correctness/noUnusedVariables: Intentionally keeping the variable
    const [selectedFeedbackOption, setSelectedFeedbackOption] = useState<string | null>(null);
    const [userFeedbackValue, setUserFeedbackValue] = useState<string>("");
    const [showOtherInput, setShowOtherInput] = useState(false);

    // these are the main option and follow up option selected to be sent to mixpanel
    const [category, setCategory] = useState<string | null>(null);
    const [followUp, setFollowUp] = useState<string | null>(null);

    const [currentFeedbackPopupContent, setCurrentFeedbackPopupContent] = useState<FeedbackObject | null>(null);

    const resetFeedbackSubmissions = useCallback(() => {
        setSubmissions({});
    }, []);

    const openFeedbackPopup = useCallback(
        ({
            feedbackFor,
            onSubmitHandler,
            rating,
        }: {
            feedbackFor: FeedbackEvents;
            onSubmitHandler?: FeedbackSubmitHandler;
            rating: number;
        }) => {
            setCurrentFeedbackEvent(feedbackFor);
            setCurrentFeedbackPopupContent(feedbackPopupContent[feedbackFor]);
            setIsFeedbackPopupOpen(true);
            setOnSubmitHandler(() => onSubmitHandler);
            setRating(rating);
        },
        []
    );

    const updateCurrentFeedbackPopupContent = useCallback((content: FeedbackObject, newLevel?: number) => {
        // timeout is to show the selection grey ui for a bit better ux
        setTimeout(() => {
            setCurrentFeedbackPopupContent(content);

            if (content?.showTextInputByDefault) {
                setShowOtherInput(true);
            }

            if (newLevel) {
                setFeedbackPopupLevel(newLevel);
            }
        }, 200);
    }, []);

    const submitFeedback = useCallback(
        (passedFollowUp: string | null = null) => {
            if (rating && onSubmitHandler) {
                onSubmitHandler({
                    rating,
                    reason: userFeedbackValue,
                    category: category || "",
                    followUp: passedFollowUp || followUp || "",
                });
            }
            closeFeedbackPopup();
            toast.success("Thanks for your feedback!");
        },
        [category, followUp, onSubmitHandler, rating, userFeedbackValue]
    );

    const handleGoBack = useCallback(() => {
        if (feedbackPopupLevel > 0) {
            setCurrentFeedbackPopupContent(feedbackPopupContent[currentFeedbackEvent]);
            setFeedbackPopupLevel(feedbackPopupLevel - 1);
            setShowOtherInput(false);
            setSelectedFeedbackOption(null);
            setUserFeedbackValue("");
            setCategory(null);
            setFollowUp(null);
        }
    }, [currentFeedbackEvent, feedbackPopupLevel]);

    const handleOptionClick = useCallback(
        (option: string) => {
            if (feedbackPopupLevel === 0) {
                setCategory(option);
            }

            if (feedbackPopupLevel > 0) {
                setFollowUp(option);
            }

            setSelectedFeedbackOption(option);

            //  check if option has a follow up question
            const followUpContent: FeedbackObject | undefined = currentFeedbackPopupContent?.followUps?.[option];

            // if more than 1 level of follow up is brought up, fix later
            // use currentFeedbackPopupContent to get followUpContent above then

            if (followUpContent) {
                updateCurrentFeedbackPopupContent(followUpContent, feedbackPopupLevel + 1);
                return;
            }

            if (option.toLowerCase() === "other") {
                setShowOtherInput(true);
                return;
            }

            // ask for anything else if non-follow up option is selected on root level
            if (feedbackPopupLevel === 0) {
                updateCurrentFeedbackPopupContent(
                    feedbackPopupContent[currentFeedbackEvent]?.followUps["Anything else"],
                    feedbackPopupLevel + 1
                );
                return;
            }

            // if no follow up, save and close close popup, timeout for the same reason as
            // updateCurrentFeedbackPopupContent
            setTimeout(() => {
                let followUpToSubmit = "";
                if (feedbackPopupLevel > 0) {
                    followUpToSubmit = option;
                }
                submitFeedback(followUpToSubmit);
            }, 200);
        },
        [
            feedbackPopupLevel,
            currentFeedbackPopupContent?.followUps,
            updateCurrentFeedbackPopupContent,
            currentFeedbackEvent,
            submitFeedback,
        ]
    );

    const closeFeedbackPopup = () => {
        setIsFeedbackPopupOpen(false);
        setCurrentFeedbackEvent("");
        setSelectedFeedbackOption(null);
        setUserFeedbackValue("");
        setFeedbackPopupLevel(0);
        setCurrentFeedbackPopupContent(null);
        setShowOtherInput(false);
        setOnSubmitHandler(undefined);
        setRating(null);
        setCategory(null);
        setFollowUp(null);
    };

    return (
        <FeedbackFlowContext.Provider
            value={{
                openFeedbackPopup,
                closeFeedbackPopup,
                handleOptionClick,
                showOtherInput,
                userFeedbackValue,
                setUserFeedbackValue,
                isFeedbackPopupOpen,
                currentFeedbackPopupContent,
                submitFeedback,
                handleGoBack,
                feedbackPopupLevel,
                submissions,
                setSubmissions,
                resetFeedbackSubmissions,
            }}>
            {children}
            <LazyLoaded load={isFeedbackPopupOpen}>
                <FeedbackFlowPopup />
            </LazyLoaded>
        </FeedbackFlowContext.Provider>
    );
};

export const useFeedbackFlowContextSelector = <T,>(selector: (state: FeedbackFlowContextValue) => T) =>
    useContextSelector(FeedbackFlowContext, selector);

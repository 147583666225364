import { useCurrentUser } from "@/hooks/user/useCurrentUser";
import { useEffect, useRef } from "react";
import { progressingEvent, ProgressingEventMiscPayload } from "@/hooks/gamification/monitoring/progressing";
import { checkStreakStatus } from "@/hooks/gamification/monitoring/streakMonitoring/checkStreakStatus";
import { checkStreakLongPauseStatus } from "@/hooks/gamification/monitoring/streakMonitoring/checkStreakLongPauseStatus";

export const useStreakStatusMonitor = () => {
    const { user } = useCurrentUser();
    const hasCheckedStreakStatus = useRef(false);

    useEffect(() => {
        const asyncEffect = async () => {
            if (hasCheckedStreakStatus.current) return;
            if (!user) return;

            hasCheckedStreakStatus.current = true;

            await checkStreakLongPauseStatus(user);
            await checkStreakStatus(user);

            progressingEvent.notify({ [ProgressingEventMiscPayload.READY_TO_SHOW_ALERTS]: {} });
        };

        asyncEffect();
    }, [user]);
};
